import shapeLeft from '../images/login_shape_left.svg';
import shapeRight from '../images/login_right_shape.svg';
import hiIcon from '../images/hi_icon.svg';
import inputCheck from '../images/input_check.svg';
import showEye from '../images/show_eye.svg';
import hideEye from '../images/hide_eye.svg';
import logo from '../images/favicon.png';
import React from 'react';
import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha
  } from 'react-google-recaptcha-v3';

  import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const ForgotPassword = () => {


    const [email, setEmail] = React.useState("");

    


    const sendEmail = () => {
        const data = {
            email: email,
        }
        fetch(`${process.env.REACT_APP_BASE_URL}forgotPassword`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(res => res.json()).then(data => {
            if (data.success ) {
                toast.success("Email sent! - Remember to check your spam folder")
            } else {
                toast.error("Email not found!")
            }
        })
    }

    return (
        <div class="main-wrapper">



        <div class="main_content">
            <div class="login_wrapper">
                <div class="login_left_shape">
                    <img src={shapeLeft} alt="shape"/>
                </div>
                <div class="login_right_shape">
                    <img src={shapeRight} alt="shape"/>
                </div>
                <div class="login_box">
                    <div class="login_common_title">
                    <img src={logo} alt="shape"/>
                        
                    </div>
                    <h2>Trackd</h2>
                    <div class="login_wlc_note">
                        <h3>Forgot Password? <img src={hiIcon} alt="icon"/></h3>
                        <p>Submit your email below and if there is an account with that email, it will be sent a password reset link</p>
                    </div>
                    <div class="login_form">
                        <form id="login_form" onSubmit={(e)=> e.preventDefault()}>
                            <div class="coomon_input_filed email_check">
                                <p>Email Address</p>
                                <input type="email" placeholder="sarah_adams@gmail.com" name="email" required value={email} onChange={(e)=> setEmail(e.target.value)}/>
                                <img class="check_icon" src={inputCheck} alt="check"/>
                            </div>
                            
                            <div class="login_btn">
                                <button type="submit" onClick={()=> sendEmail()}>Send Link</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="copyright">
                    <p>Copyright 2022 - TRACKD</p>
                    <ul>
                        <li><a href="/privacy">Privacy Policy</a></li>
                        <li><a href="/contact">Contact us</a></li>
                        <li><a href="/terms">Terms of use</a></li>
                    </ul>
                </div>
            </div>   
            <ToastContainer></ToastContainer>

        </div>
    </div>
    )
}