import $ from 'jquery';
import { hide, show, toggle } from 'slidetoggle';
import React, { useState } from 'react';
import { formatDate } from '../Util/date';
import '../js/custom.js'
import { AddEmail } from './addWhitelistEmail';
import { ToastContainer, toast } from 'react-toastify';
import { useContext } from "react"
import { AppContext } from "../../context"
import TailSpin from 'react-loading-icons/dist/esm/components/tail-spin';
import { EditSale } from './editSale';
import { useLocalStorage } from 'usehooks-ts';

// import "../css/style.css"
export const Email = () => {

    const [currency, setCurrency] = useLocalStorage("currency", "GBP")


    const formatOrderNumber = (orderNumber) => {
        if(orderNumber.length > 12){
            return orderNumber.slice(0, 11) + "..."
        }else{
            return orderNumber
        }
    }

    

    const {emaillist,setEmaillist, user} = useContext(AppContext)




    const [email, setEmail] = useState("Loading...");


    const [whitelistedemails, setWhitelistedEmails] = useState([]);
    const [gmailcode, setGmailCode] = useState("Loading...")

    const [salesEmails, setSalesEmails] = useState([]);


    const [salesMode, setSalesMode] = useState(false);

    const [newPurchaseId, setNewPurchaseId] = useState("")

    const [editedSale, setEditedSale] = useState({})



    React.useEffect(() => {

       if(emaillist.length === 0){

        fetch(`${process.env.REACT_APP_BASE_URL}getEmailImports`,{
            credentials: 'include',
            method: 'GET',
            redirect: 'follow'
        })
        .then(res => res.json())
        .then(data => {
            data.imports.reverse();
            setEmaillist(data.imports)
            setGmailCode(data.forwardCode)
        })


      
    }
    }, []);

    React.useEffect(() => {
        
        if(salesMode){
        
        fetch(`${process.env.REACT_APP_BASE_URL}getMatchedSales`, {
            credentials: 'include',
            method: 'GET',
            redirect: 'follow'
        })
        .then(res => res.json())
        .then(data => {
            setEmaillist(data.responseArray)
        })
    }else{
      fetch(`${process.env.REACT_APP_BASE_URL}getEmailImports`,{
        credentials: 'include',
        method: 'GET',
        redirect: 'follow'
    })
    .then(res => res.json())
    .then(data => {
        data.imports.reverse();
        setEmaillist(data.imports)
        setGmailCode(data.forwardCode)
    })
    }
    }, [salesMode])


    const toggleMenu = (id) => {

        document.getElementById(id).classList.toggle("open")  
        document.getElementById(id).nextElementSibling.classList.toggle("open")


    //    e.target.parentNode.classList.add("open")
    }


    const whitelistCallback = (email) => {
        setWhitelistedEmails([...whitelistedemails, email])

    }


    const deleteWhitelistedEmail = (email) => {
        fetch(`${process.env.REACT_APP_BASE_URL}deleteWhitelistedEmail`, {
            credentials: 'include',
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({email})
        }).then(() => {
            toast.success("Email deleted successfully")
            setWhitelistedEmails(whitelistedemails.filter((item) => item !== email))

        })
    }

    const openLink = (link) => {
        window.open(link, '_blank');
    }


    const updateMatchedPurchase = (saleId, purchaseId, type) => {


        fetch(`${process.env.REACT_APP_BASE_URL}updateMatchedSale`, {
            credentials: 'include',
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({saleId, purchaseId, type})
        }).then((resp) => {

            if(resp.status !== 200){
                toast.error("Error updating purchase ID")
                return
            }
            toast.success("Purchase ID updated successfully")
            let item  = emaillist.find((item) => item.id === saleId)
            item.purchaseId = purchaseId
            setEmaillist([...emaillist])

        })
    };


    const editSaleCallback = (sale) => {
        let index = emaillist.findIndex((item) => item.id === sale.id)
        emaillist[index] = sale
        setEmaillist([...emaillist])
    };

    // $('.fold-table tr.view').on("click", function (e) {
    //     // console.log(e)
    //     e.currentTarget.classList.add('open');
    //     toggle(e.currentTarget.nextElementSibling,{
    //         duration: 500,
    //         transitionFunction: 'ease-in',
    //         elementDisplayStyle: 'table-row',
    //     })

        
    //     // e.toggleclassName('open').next('.fold').slideToggle("slow");
    //     // $(this).siblings().removeclassName('open').next('.fold').slideUp("slow");
    // });
    // Text Copy Js
    // const textElement = document.getElementById("text_link");
    // const copyButton = document.getElementById("copy");

    // const copyText = (e) => {
    //     window.getSelection().selectAllChildren(textElement);
    //     document.execCommand("copy");
    //     e.target.parentNode.setAttribute("tooltip", "Copied! ✅");
    // };

    // const resetTooltip = (e) => {
    //     e.target.parentNode.setAttribute("tooltip", "Copy to clipboard");
    // };

    // copyButton.addEventListener("click", (e) => copyText(e));
    // copyButton.addEventListener("mouseover", (e) => resetTooltip(e));



    return (
        <body class="templete email_page">

        <div className="main_content">

            <div className="main_content_lg">
                <div className="common_content_pad_block">
                    <div className="header_wrapper">
                        <div className="header_wrap">
                            <div className="menu_trigger ">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16.842" height="14.677" viewBox="0 0 16.842 14.677">
                                    <g id="Close_Menu" data-name="Close Menu" transform="translate(0.5 0.707)">
                                    <path id="Shape" d="M-5.526.5H0" transform="translate(15.842 12.503)" fill="none" stroke="#535776" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/>
                                    <path id="Shape-2" data-name="Shape" d="M0,0-6.263,6.632,0,13.263" transform="translate(6.263)" fill="none" stroke="#535776" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/>
                                    <path id="Shape-3" data-name="Shape" d="M-15.842,0H0" transform="translate(15.842 6.563)" fill="none" stroke="#535776" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/>
                                    <path id="Shape-4" data-name="Shape" d="M-5.526.5H0" transform="translate(15.842 -0.368)" fill="none" stroke="#535776" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/>
                                    </g>
                                </svg>
                            </div>
                            <div className="common_heading">
                                <h1 className="header_title">Email Hub {salesMode? "(Sales)": "(Purchases)"}</h1>
                                <p className="header_content">View all forwarded emails. These are used to automatically track purchased tickets.</p>
                            </div>

                           

                            <div className="header_button_wrap">
                                
                                <div className="button_wraper"><button className="cm_btn web_btn" onClick={()=> setSalesMode(e => !e)}>Toggle Mode</button></div>
                                
                            </div>

                            <div className="header_button_wrap">
                                
                                <div className="button_wraper"><button className="cm_btn web_btn" onClick={() => openLink("https://trackd.gitbook.io/trackd/how-to/use-email-forwarding")}>Guide</button></div>
                                
                            </div>

                            
                        </div>
                    </div>
              
                    <div className="inner_content_wrapper dashboard_one_content">
                        <div className="your_ticketbox_wrap">
                            <div className="your_ticketbox_inner">
                                <p>Your TRACK'D email address</p>
                                <div id="container_wrap">
                                    <span id="text_link">
                                        {user.forwardEmail}
                                    </span>
                                    <button id="copy" tooltip="Copy to clipboard">
                                        <img src="assets/images/copy_text_icon.png" alt=""/>
                                    </button>
                                  </div>
                            </div>

                            
                        </div>

                        
                            
                        <div className="your_ticketbox_wrap">
                            <div className="your_ticketbox_inner">
                                <p>Your GMail auto-forward code</p>
                                <div id="container_wrap">
                                    <span id="text_link">
                                        {gmailcode ? gmailcode.code: "Waiting for code..."}
                                    </span>
                                    <button id="copy" tooltip="Copy to clipboard">
                                        <img src="assets/images/copy_text_icon.png" alt=""/>
                                    </button>
                                  </div>
                            </div>
                        </div>

                       
<div className="inventory_table_wrap">
  <div className="table-responsive cm_table_responsive">
    <div className="tbl-header"></div>
    <table className="cm_table fold-table">
      <thead className="cm_thead">
        <tr className="cm_thead_list">
          <th className="th_list cm_from">
            <div className="th_item_wrap">
              <h6>{salesMode ? null: "From" }</h6>
            </div>
          </th>
          <th className="th_list cm_eventname">
            <div className="th_item_wrap">
              <h6>Event Name</h6>
            </div>
          </th>
          <th className="th_list cm_date">
            <div className="th_item_wrap">
              <h6>Event Date</h6>
            </div>
          </th>
          <th className="th_list cm_order">
            <div className="th_item_wrap">
              <h6>Order</h6>
            </div>
          </th>
        </tr>
      </thead>

      {salesMode ? (
        emaillist ? (
          <tbody className="cm_tbody">
            {emaillist.map((item, index) => (
              <React.Fragment key={index}>
                <tr className="cm_tr view" onClick={(e) => toggleMenu(index)} id={index}>
                  <td className="cm_td">
                    <h4>{item.retailer}</h4>
                  </td>
                  <td className="cm_td">
                    <h4>{item.name}</h4>
                  </td>
                  <td className="cm_td">
                    <h4>{formatDate(new Date(item.date))}</h4>
                  </td>
                  <td className="cm_td">
                    <h4>{formatOrderNumber(item.orderNumber)}</h4>
                  </td>
                </tr>
                <tr className="fold">
                  <td colSpan="8">
                    <div className="fold-content">
                      <div className="row">
                        <div className="col-4">
                          <div className="table_inventory_details">
                            <h3 className="table_inventory_title">Sale Details</h3>
                            <div className="table_inventory_content">
                              <ul className="table_inventory_list">
                                <li className="table_inventory_item">
                                  <p>Ticket Price:</p>
                                  <h6>{new Intl.NumberFormat('en-GB', {
                                            style: 'currency',
                                            currency: item.currency,
                                        }).format(item.price / item.quantity)}</h6>
                                </li>
                                <li className="table_inventory_item">
                                  <p>Total Price:</p>
                                  <h6>{new Intl.NumberFormat('en-GB', {
                                            style: 'currency',
                                            currency: item.currency,
                                        }).format(item.price)}</h6>
                                </li>
                                <li className="table_inventory_item">
                                  <p>Tickets:</p>
                                  <h6>{item.quantity}</h6>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <div className="col-4">
                          <div className="table_inventory_details">
                            <ul className="table_inventory_list">
                              
                              <li className="table_inventory_item">
                                <p>Section:</p>
                                <h6>{item.section}</h6>
                              </li>
                              <li className="table_inventory_item">
                                <p>Row:</p>
                                <h6>{item.row}</h6>
                              </li>
                              <li className="table_inventory_item">
                                <p>Seats:</p>
                                <h6>{item.seat.join()}</h6>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="col-4">
                          <div className="table_inventory_details">
                            <ul className="table_inventory_list">
                              
                              
                              <li className="table_inventory_item">
                                <p>Order number:</p>
                                <h6>{item.orderNumber}</h6>
                              </li>
                              
                              <li className="table_inventory_item">
                                <p>Email Address:</p>
                                <h6>{item.email}</h6>
                              </li>

                              <li className="table_inventory_item">
                                <p>Matched Purchase ID:</p>
                                <h6>{item.purchaseId}</h6>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <p style={{padding:"5px"}}>Update purchase ID? </p>
                        
                        <label class="input_wrap" style={{width:"18%"}}>
                                    <input type="number" placeholder="New Purchase ID" onChange={e=> setNewPurchaseId(e.target.value)}/>
                                    <span class="calendar_icon">
                                                                                
                                    </span>
                                </label>
                            
                        <button className="cm_btn web_btn" onClick={()=> updateMatchedPurchase(item.id,Number(newPurchaseId), item.type)}
                        
                        style={{width:"18%", marginLeft:"5px", padding:"initial"}}
                        >Match</button>

                        { item.type === "Matched"?
                        <button className="cm_btn web_btn" data-bs-toggle="modal" data-bs-target="#exampleModal12" onClick={()=> setEditedSale(item)}
                        style={{width:"18%", marginLeft:"5px", padding:"initial"}}
                        >Edit</button>:null
                        }

                        <p style={{marginLeft:0, marginTop:"1%"}}>If something looks wrong, please edit this via the inventory page and let us know - this feature is still in beta</p>

                        
                      
                      </div>
                    </div>
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        ) : (
          <TailSpin className="spinner" color="#000000" />
        )
      ) : (
        <tbody className="cm_tbody">
          {emaillist.map((item, index) => (
            <React.Fragment key={index}>
              <tr className="cm_tr view" onClick={(e) => toggleMenu(index)} id={index}>
                <td className="cm_td">
                  <h4>{item.retailer}</h4>
                </td>
                <td className="cm_td">
                  <h4>{item.name}</h4>
                </td>
                <td className="cm_td">
                  <h4>{formatDate(new Date(item.date))}</h4>
                </td>
                <td className="cm_td">
                  <h4>{formatOrderNumber(item.orderNumber)}</h4>
                </td>
              </tr>
              <tr className="fold">
                <td colSpan="8">
                  <div className="fold-content">
                    <div className="row">
                      <div className="col-4">
                        <div className="table_inventory_details">
                          <h3 className="table_inventory_title">Purchase Details</h3>
                          <div className="table_inventory_content">
                            <ul className="table_inventory_list">
                              <li className="table_inventory_item">
                                <p>Ticket Price:</p>
                                <h6>{new Intl.NumberFormat('en-GB', {
                                            style: 'currency',
                                            currency: item.currency,
                                        }).format(item.price / item.quantity)}</h6>
                              </li>
                              <li className="table_inventory_item">
                                <p>Total Price:</p>
                                <h6>{new Intl.NumberFormat('en-GB', {
                                            style: 'currency',
                                            currency: item.currency,
                                        }).format(item.price)}</h6>
                              </li>
                              <li className="table_inventory_item">
                                <p>Tickets:</p>
                                <h6>{item.quantity}</h6>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="col-4">
                        <div className="table_inventory_details">
                          <ul className="table_inventory_list">
                            <li className="table_inventory_item">
                              <p>Location:</p>
                              <h6>{item.location}</h6>
                            </li>
                            <li className="table_inventory_item">
                              <p>Section:</p>
                              <h6>{item.section}</h6>
                            </li>
                            <li className="table_inventory_item">
                              <p>Row:</p>
                              <h6>{item.row}</h6>
                            </li>
                            <li className="table_inventory_item">
                              <p>Seats:</p>
                              <h6>{item.seat.join()}</h6>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="col-4">
                        <div className="table_inventory_details">
                          <ul className="table_inventory_list">
                            <li className="table_inventory_item">
                              <p>Purchase date:</p>
                              <h6>{formatDate(new Date(item.purchaseDate))}</h6>
                            </li>
                            <li className="table_inventory_item">
                              <p>Place bought:</p>
                              <h6>{item.retailer}</h6>
                            </li>
                            <li className="table_inventory_item">
                              <p>Order number:</p>
                              <h6>{item.orderNumber}</h6>
                            </li>
                            <li className="table_inventory_item">
                              <p>Ticket type:</p>
                              <h6>{item.ticketType}</h6>
                            </li>
                            <li className="table_inventory_item">
                              <p>Email Address:</p>
                              <h6>{item.email}</h6>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <p>If something looks wrong, please edit this via the inventory page and let us know - this feature is still in beta</p>
                    </div>
                  </div>
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      )}
    </table>
  </div>
</div>
 
                        </div>  

                        
                        <br></br>
                       
                        

                        

                        
                        

                        

                    </div>
              </div>

              

   
          </div>
          <ToastContainer />
          <EditSale sale={editedSale} callback={editSaleCallback}/>
          </body>

          

          


    )

}