import favicon from '../images/favicon.png';
import goto from '../images/got_app_icon.svg';
import btn_img from '../images/btn_img.svg';
import hero_banner_img from '../images/highlight pic.png';
import our_features1 from '../images/Trackd emails no pc.png';
import our_features2 from '../images/Trackd inventory no pc.png';
import our_features3 from '../images/Trackd calendar no pc.png';
import our_features5 from '../images/Trackd expenses no pc.png';
import our_features6 from '../images/Trackd dashboard no pc.png';
import contact_ic1 from '../images/contact_ic1.svg';
import contact_ic2 from '../images/contact_ic2.svg';
import contact_ic3 from '../images/contact_ic3.svg';
import newsleter from '../images/newsleter.png';
import $ from 'jquery';
import { Fade } from "react-awesome-reveal";
import { useNavigate } from 'react-router-dom';
import logo  from '../images/logo purple no bg.png';


export const Home = () => {

    const navigate = useNavigate();


    $(document).ready(function () {
        //Menu Js
        $('.menu_toggle_btn').click(function () {
            $('body').toggleClass('cm-overflow');
        });

        $('.menu_block ul li a').click(function () {
            $('body').removeClass('cm-overflow');
        });

         //Sticky Header
        $(window).scroll(function(){
            var sticky = $('.sticky'),
                scroll = $(window).scrollTop();

            if (scroll >= 100) sticky.addClass('fixed');
            else sticky.removeClass('fixed');
        });

        $('.menu_block ul li a, .footer_menu ul li a').click(function(){
            var height = $(window).height();
            var width = $(window).width();

            if (height > width) {
                $('html, body').animate({
                    scrollTop: $( $.attr(this, 'href') ).offset().top - 50
                }, 1500);
                return false;
            }
            else if (height < width) {
                $('html, body').animate({
                    scrollTop: $( $.attr(this, 'href') ).offset().top 
                }, 1500);
                return false;
            }        
        });

    });

    return (
        // <head>
        //          <title>Landing Page</title>
        
        //        <link type="image/x-icon" rel="shortcut icon" href="assets/images/favicon.png" />
        
        //     <meta charset="UTF-8" />
        //         <meta name="HandheldFriendly" content="true" />
        //      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        
        //         <meta name="description" content="" />
        //         <meta name="keywords" content="" />
        //         <meta name="author" content="" />
        
        //        <script
        //         type="text/javascript"
        //         src="https://app.termly.io/embed.min.js"
        //         data-auto-block="on"
        //         data-website-uuid="df258c72-7d14-4611-9b76-75dce07b58b9"
        //         ></script>
        
        //         <script defer src="assets/js/pages/index.js"></script>
        
        //         <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
        
        //         <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap" rel="stylesheet"/>
        
        //         <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/jquery-nice-select/1.1.0/css/nice-select.min.css" />
        
        //         <link rel="stylesheet" type="text/css" href="assets/css/style.css"/>
        //         <link rel="stylesheet" type="text/css" href="assets/css/responsive.css">
        
        
            <body style={{
                width:"100%", overflow:"hidden" 
            }}>
                <meta name="viewport" content="initial-scale=1, maximum-scale=1"></meta>
                <div className="main-wrapper">
                    <div className="cm_header_wrapper">
                        <header className="sticky">  
                            <div className="container">
                                <div className="header_menu">
                                    <div className="w_logo">
                                        <img src={favicon} alt="img"/>
                                    </div>
                                    <div className="menu_item_wrap menu_block">
                                        <ul>
                                            <li><a href="index">Home</a></li>
                                            <li><a href="#features_sec"> Features </a></li>
                                            <li><a href="#pricing_sec"> Pricing</a></li>
                                            <li><a href="#contact_sec"> Contact Us</a></li>
                                        </ul>
                                    <div className="go_to_app_wrap">
                                        <a onClick={()=>{navigate("/app/dashboard")}} className="common_btn">Go to app <img src={goto} alt="ic"/></a>
                                    </div>
                                </div>
                                <div className="menu_toggle_btn">
                                    <span> </span>
                                    <span> </span>
                                    <span> </span>
                                </div>
                            </div>
                        </div>
                        
                    </header>
                </div>
        
                <div className="main_content">
                   <div className="hero_banner_section">
                        <div className="container">
                            <div className="hero_content_block">
                                <h1>Tickets, Simplified</h1>
                                <h1>Tickets, TRACK'D</h1>
                                <p>Out with the ugly, time-consuming spreadsheets, in with TRACK'D - the simple, automated ticket tracker.</p>
                                <a href="https://whop.com/checkout/plan_rZQM5DgxXI3lX/" className="common_btn">Sign up for your free trial<img src={btn_img} alt="img"/></a>
                            </div>
                            <div className="hero_bg_img_block">
                                <img src={hero_banner_img} alt="img" className='mobile-image' style={{
                                        marginLeft: "50%",
                                        height:"50%",
                                        width:"50%"
                                }}/>
                            </div>
                        </div>
                   </div>

                    <Fade cascade={true} delay={500} damping={2000}>
                   <div id="features_sec" className="our_features_section cm_pt">
                        <div className="container">
                            <div className="inner_common_title text-center">
                                <span className="section_label">Features</span>
                                <h2>Built by ticket sellers,<br></br> for ticket sellers</h2>
                                <p>TRACK'D was built with you in mind. Our features help automate as much of the tedious admin process behind ticket management as possible. Read more about them below: <br></br></p>
                            </div>
        
                            <div className="our_features_block">
                                <div className="row">
                                    <div className="col-md-6 col-lg-4">
                                        <div className="our_features_box of_box_1">
                                            <div className="our_features_img">
                                                <img src={our_features6} alt="img"/>
                                            </div>
                                            <div className="our_features_content">
                                                <h3>KPI Dashboard</h3>
                                                <p>Make sure you’re always up to date with your business by viewing your key numbers on the dashboard. Also get an estimated value of all unsold tickets. </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <div className="our_features_box of_box_2">
                                            <div className="our_features_img">
                                                <img src={our_features2} alt="img"/>
                                            </div>
                                            <div className="our_features_content">
                                                <h3>View your inventory</h3>
                                                <p>Easily view and filter on all of your inventory. See all key details including the seat details, cost, status, profit, ROI and much more along with a link to take you directly to the viagogo listing page.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <div className="our_features_box of_box_3">
                                            <div className="our_features_img">
                                                <img src={our_features1} alt="img"/>
                                            </div>
                                            <div className="our_features_content">
                                                <h3>AI powered automatic ticket tracking</h3>
                                                <p>Forward emails to your unique TRACK'D email address, and let us do the rest. Harnessing the power of AI, your inventory will then automatically update to reflect the email contents. You can also manually track purchases and sales.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <div className="our_features_box of_box_4">
                                            <div className="our_features_img">
                                                <img src={our_features3} alt="img"/>
                                            </div>
                                            <div className="our_features_content">
                                                <h3>Event and payout tracking</h3>
                                                <p>Easily view when events are coming up and when you can expect your next payouts.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <div className="our_features_box of_box_5">
                                            <div className="our_features_img">
                                                <img src={our_features5} alt="img"/>
                                            </div>
                                            <div className="our_features_content">
                                                <h3>Expense tracker</h3>
                                                <p>Track all expenses relating to tickets here, whether it be monthly group costs or anything else.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <div className="our_features_box of_box_6">
                                            <div className="our_features_img">
                                                <img src={our_features6} alt="img"/>
                                            </div>
                                            <div className="our_features_content">
                                                <h3>Free setup</h3>
                                                <p>Don’t want to spend the time transferring from a spreadsheet to TRACK’D? Don’t worry we will do it for free! Just reach out in a Discord ticket and we’ll be happy to help.</p>
                                            </div>
                                        </div>
                                    </div>
        
                                </div>
                            </div>
                        </div>
                   </div>
                   </Fade>

                    <Fade duration={1500}>
                   <div id="pricing_sec" className="pricing_section cm_pt">
                        <div className="container">
                            <div className="inner_common_title text-center">
                                <span className="section_label">Pricing</span>
                                <h2>Join the team and level up your ticket reselling</h2>
                                <p>Upgrade your admin by taking advantage of the beautiful and easy to use dashboard and save yourself time and stress by automating - all for a low monthly fee.</p>
                            </div>
        
                            <div className="pricing_block">
                                <div className="row">
                                   
                                    <div className="col-lg-6">
                                        <div className="pricing_box">
                                            <div className="pricing_head">
                                                <div className="pricing_paln_name">
                                                    <h3>TRACK’D app subscription</h3>
                                                </div>
                                                <div className="pricing_plan_price">
                                                    <span className="euro_ic"><sup>£</sup></span>
                                                    <h3>10.49</h3>
                                                    <span className="month_ic"><sub>/mo</sub></span>
                                                </div>
                                            </div>
                                            <div className="pricing_body">
                                                <ul>
                                                    <li>Manually or automatically track purchases</li>
                                                    <li>Manually or automatically track sales</li>
                                                    <li>View key performance indicators</li>
                                                    <li>View your upcoming events with our calendar</li>
                                                    <li>Bulk upload data</li>
                                                    <li>Export data</li>
                                                    <li>Get set up help for FREE</li>
                                                    <li>Track your expenses</li>
                                                    <li>See the estimated value of your tickets</li>
                                                    <li>+ more with more to come!</li>
                                                </ul>
                                            </div>
                                            <div className="pricing_footer">
                                                <a href="https://whop.com/checkout/plan_rZQM5DgxXI3lX" className="common_btn">Get Started Now <img src={btn_img} alt="img"/></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                   </div>
                   </Fade>
        
                   <div id="contact_sec" className="contact_Us_section cm_pt">
                        <div className="container">
                            <div className="inner_common_title text-center">
                                <span className="section_label">Contact Us</span>
                                <h2>Have any questions? Get in touch!</h2>
                                <p>It's easy to get in touch with us. <br></br>Joining the Discord support server will get you the quickest response but we are also available to contact via email, Twitter, or by filling in the form below.</p>
                            </div>
        
                            <div className="contact_Us_block">
                                <div className="contact_Us_col_left">
                                <ul>
                                            <li>
                                                <div className="contact_email_ic">
                                                    <img src={contact_ic1} alt="ic"/>
                                                    <h3>Email</h3>
                                                    <a href="mailto:trackdhelp@gmail.com">help@trackd.gg</a>
                                                </div>
                                                <div className="contact_email_ic">
                                                    <img src={contact_ic3} alt="ic"/>
                                                    <h3>discord</h3>
                                                    <a href="https://discord.gg/h6r97xa8fh" target="_blank">TRACK'D Support Server</a>
                                                </div>
                                            </li>
                                        </ul>
                                    {/* <div className="contact_us_form">
                                        <form>
                                            <div className="cm_input_hold">
                                                <input type="text" placeholder="Enter your name"/>
                                            </div>
                                            <div className="cm_input_hold">
                                                <input type="email" placeholder="Enter your email"/>
                                            </div>
                                            <div className="cm_input_hold">
                                                <textarea placeholder="Your message..."></textarea>
                                            </div>
                                            <div className="contact_form_submit">
                                                <button className="common_btn">Send <img src={btn_img} alt="img"/></button>
                                            </div>
                                        </form>
                                    </div> */}
                                </div>
                                {/* <div className="contact_Us_col_right">
                                    <div className="contact_icon">
                                        <ul>
                                            <li>
                                                <div className="contact_email_ic">
                                                    <img src={contact_ic1} alt="ic"/>
                                                    <h3>Email</h3>
                                                    <a href="mailto:help@trackd.gg">help@trackd.gg</a>
                                                </div>
                                                <div className="contact_email_ic">
                                                    <img src={contact_ic3} alt="ic"/>
                                                    <h3>discord</h3>
                                                    <a href="https://discord.gg/h6r97xa8fh" target="_blank">TRACK'D Support Server</a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                   </div>

                   <br></br>
        
                  
        
                </div>
                 <div className="footer_wrapper">
                    <footer>
                        <div className="container">
                            <div className="footer_block">
                                <div className="footer_left_col">
                                    <div className="footer_content">
                                        <div className="footer_logo">
                                             <img src={logo} alt="img" style={{    width: "25%"}}/>
                                        </div>
                                        <div className="footer_info">
                                            <p>Tickets, simplified.</p>
                                        </div>
                                        <div className="footer_menu">
                                            <ul>
                                                <li><a href="index">Home</a></li>
                                                <li><a href="#features_sec"> Features </a></li>
                                                <li><a href="#pricing_sec"> Pricing</a></li>
                                                <li><a href="#contact_sec"> Contact Us</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>  
                                {/* <div className="footer_right_col">
                                    <div className="store_logo">
                                        <a href="https://play.google.com" target="_blank"><img src="assets/images/play_store1.svg" alt="store"/></a>
                                        <a href="https://www.apple.com" target="_blank"><img src="assets/images/play_store2.svg" alt="store"/></a>
                                    </div>
                                </div>    */}
                            </div>
                        </div>
                        <div className="footer_copyright">
                            <div className="container">
                                <div className="copyright_block">
                                    <p><span>©</span> 2023 TRACK'D All rights reserved.</p>
                                   
                                </div>
                            </div>
                        </div>
                    </footer>
                 </div>
            </div>
            <script src="assets/js/jquery-3.6.0.min.js"></script>
            <script src="assets/js/bootstrap.bundle.js"></script> 
            <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-nice-select/1.1.0/js/jquery.nice-select.min.js"></script>
        
            <script src="assets/js/custom.js"></script>
        
            {/* <script>
                 $(document).ready(function () {
                    //Menu Js
                    $('.menu_toggle_btn').click(function () {
                        $('body').toggleclassName('cm-overflow');
                    });
        
                    $('.menu_block ul li a').click(function () {
                        $('body').removeclassName('cm-overflow');
                    });
        
                     //Sticky Header
                    $(window).scroll(function(){
                        var sticky = $('.sticky'),
                            scroll = $(window).scrollTop();
        
                        if (scroll >= 100) sticky.addclassName('fixed');
                        else sticky.removeclassName('fixed');
                    });
        
                    $('.menu_block ul li a, .footer_menu ul li a').click(function(){
                        var height = $(window).height();
                        var width = $(window).width();
        
                        if (height > width) {
                            $('html, body').animate({
                                scrollTop: $( $.attr(this, 'href') ).offset().top - 50
                            }, 1500);
                            return false;
                        }
                        else if (height < width) {
                            $('html, body').animate({
                                scrollTop: $( $.attr(this, 'href') ).offset().top 
                            }, 1500);
                            return false;
                        }        
                    });
        
                });
            </script> */}
        </body>

    )};
