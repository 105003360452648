
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocalStorage } from "usehooks-ts"
import { currencies } from '../../constants/constants';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { convertEuropeanNumber } from '../Util/EUnumberFormatter';

const useStyles = makeStyles({
    
    input: {
        "&.Mui-focused": {
          border: "none",
          
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
            borderRadius: 'none'
          },
          width: '470px',
          right: '20px'
      }
});


export const EditPurchase = ({
    name,
    date,
    purchaseDate,
    location,
    section,
    row,
    seat,
    retailer,
    tickets,
    price,
    orderNumber,
    email,
    currency,
    ticketType,
    setTicketType,
    setName,
    setDate,
    setPurchaseDate,
    setLocation,
    setSection,
    setRow,
    setSeat,
    setRetailer,
    setTickets,
    setPrice,
    setOrderNumber,
    setEmail,
    setCurrency,
    id,
    paymentMethod, 
    setPaymentMethod,
    callback


}) => {

    const handleEdit = () => {
        const purchase = { name, date, location, section, row, seat, retailer, tickets, price, orderNumber, email, id, purchaseDate, ticketType, currency, paymentMethod };





        let wrongFields = []
        //validate every field
        for (const i in purchase) {
            if (i === 'name' || i === 'location' || i === 'date' || i === 'section') {

                if (purchase[i] === "") {
                wrongFields.push(i)
                }

                continue
            }
        }



        if (wrongFields.length > 0) {
            toast("Please fill in all fields")
            return
        }

                purchase.price = convertEuropeanNumber(purchase.price)


        fetch(`${process.env.REACT_APP_BASE_URL}editManualPurchase`, {
            credentials: 'include',
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(purchase)
        }).then(() => {
            toast.success("Purchase edited")
            callback(name, date, location, section, row, seat, retailer, tickets, purchase.price, orderNumber, email, id, purchaseDate, ticketType, paymentMethod)

        })
    }


    const handleSeatChange = (e) => {
        if (e.target.value.includes(',')) {
            let seats = e.target.value.split(',')
            setSeat(seats)
        } else {
            setSeat([e.target.value])
        }
    }

    const retailerOptions = ["AXS", "gigsAndTours", "See", "Ticketmaster"];
    const typeOptions = ["E-Ticket", "Mobile", "Paper"];
    const emailOptions = [window.sessionStorage.getItem("email")];
    const classes = useStyles();



    return (

        <>
            <div class="modal fade" id="exampleModal3" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div class="modal_header_content">
                                <h5 class="modal-title" id="exampleModalLabel">Edit your purchase</h5>
                                <p>Please use this to edit the event information, use the "edit listing" button to edit any listing deatils</p>
                            </div>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.00098 5L19.0005 18.9996" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M4.99947 18.9996L18.999 5" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="modal_body_details">
                                <form class="modal_form">
                                    <div class="cm_form_wrap">
                                        <div class="cm_col_6">
                                            <div class="cm_date_box modal_cm_width">
                                                <label class="form">Event Name</label>
                                                <div class="input_wrap">
                                                    <input type="text" placeholder="Ricky Gervais" value={name} onChange={e => setName(e.target.value)} />
                                                </div>
                                            </div>
                                            <div class="cm_date_box modal_cm_width">
                                                <label>Event Date</label>
                                                <div class="input_wrap">
                                                    <DatePicker selected={date}
                                                        dateFormat="d MMMM yyyy"
                                                        popperPlacement="top-start"
                                                        onChange={date => setDate(date)}
                                                    />
                                                </div>
                                            </div>

                                            <div class="cm_date_box modal_cm_width">
                                                <label>Purchase Date</label>
                                                <div class="input_wrap">
                                                    <DatePicker selected={purchaseDate}
                                                        dateFormat="d MMMM yyyy"
                                                        popperPlacement="top-start"
                                                        onChange={date => setPurchaseDate(date)}
                                                    />
                                                </div>
                                            </div>
                                            <div class="cm_date_box modal_cm_width">
                                                <label class="form">Event Location</label>
                                                <div class="input_wrap">
                                                    <input type="text" placeholder="London" value={location} onChange={e => setLocation(e.target.value)} />
                                                </div>
                                            </div>
                                            <div class="cm_date_box modal_cm_width">
                                                <label>Section</label>
                                                <div class="input_wrap">
                                                    <input type="text" placeholder="B" value={section} onChange={e => setSection(e.target.value)} />
                                                </div>
                                            </div>
                                            <div class="cm_date_box modal_cm_width">
                                                <label>Row</label>
                                                <div class="input_wrap">
                                                    <input type="text" placeholder="H" value={row} onChange={e => setRow(e.target.value)} />
                                                </div>
                                            </div>
                                            <div class="cm_date_box modal_cm_width">
                                                <label>Seats</label>
                                                <div class="input_wrap">
                                                    <input type="text" placeholder="04" value={seat.join()} onChange={e => handleSeatChange(e)} />
                                                </div>
                                            </div>

                                        </div>


                                        <div class="cm_col_6">
                                            <div class="cm_date_box modal_cm_width">
                                                <label>Ticket Type </label>
                                                <div class="input_wrap">
                                                    <Autocomplete
                                                        disableClearable
                                                        freeSolo
                                                        className={classes.input}
                                                        options={typeOptions}
                                                        value={ticketType}
                                                        onChange={(event, value) => setTicketType(value)}
                                                        renderInput={(params) => <TextField {...params}  placeholder={ticketType} className={classes.input} onChange={(e)=>setTicketType(e.target.value)} />}
                                                    />
                                                </div>
                                            </div>
                                            <div class="cm_date_box modal_cm_width">
                                                <label>Retailer</label>
                                                <div class="input_wrap">
                                                    <Autocomplete
                                                        disableClearable
                                                        freeSolo
                                                        className={classes.input}
                                                        options={retailerOptions}
                                                        value={retailer}
                                                        onChange={(event, value) => setRetailer(value)}
                                                        renderInput={(params) => <TextField {...params}  placeholder={retailer} className={classes.input} onChange={(e)=> setRetailer(e.target.value)}/>}
                                                    />
                                                </div>
                                            </div>
                                            <div class="cm_date_box modal_cm_width">
                                                <label>Tickets</label>
                                                <div class="input_wrap">
                                                    <input type="number" placeholder="2" value={tickets} onChange={e => setTickets(e.target.value)} />
                                                </div>
                                            </div>
                                            <div class="cm_date_box modal_cm_width">
                                                <label>Total Price incl Fees</label>
                                                <div class="input_wrap">
                                                    <input type="text" placeholder="£99.99 (intl format)" value={price} onChange={e => setPrice(e.target.value)} />
                                                </div>
                                            </div>

                                            <div class="cm_date_box modal_cm_width">
                                                <label>Order Number</label>
                                                <div class="input_wrap">
                                                    <input type="text" placeholder="55-50752/UK1" value={orderNumber} onChange={e => setOrderNumber(e.target.value)} />
                                                </div>
                                            </div>
                                            <div class="cm_date_box modal_cm_width">
                                                <label>Email used</label>
                                                <div class="input_wrap">
                                                    <Autocomplete
                                                        disableClearable
                                                        freeSolo
                                                        className={classes.input}
                                                        options={emailOptions}
                                                        onChange={(event, value) => setEmail(value)}
                                                        value={email}
                                                        renderInput={(params) => <TextField {...params}  placeholder={"hello@trackd.gg"} className={classes.input} value={email} onChange={e=> setEmail(e.target.value)}/>}
                                                    />
                                                </div>
                                            </div>

                                            <div class="cm_date_box modal_cm_width">
                                                <label>Currency</label>
                                                <div class="input_wrap">
                                                    <select class="nice-select wide" style={{
                                                        paddingLeft: "0px",
                                                        width: "106%",
                                                        marginLeft: "-24px"
                                                    }} value={currency} onChange={e => setCurrency(e.target.value)}>
                                                        {currencies.map((currency) => (
                                                            <option data-display={currency}>{currency}</option>
                                                        ))}

                                                    </select>
                                                </div>
                                            </div>
                                            <div class="cm_date_box modal_cm_width">
                                                <label>Payment Method</label>
                                                <div class="input_wrap">
                                                    <input type="text" placeholder="PayPal" onChange={e => setPaymentMethod(e.target.value)} />
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="button_wraper">
                                <button class="cm_btn web_btn" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleEdit()}>Save</button>
                                <button class="cm_btn web_btn grey_color" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        </>
    )
}