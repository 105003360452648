import React, { useCallback} from "react"
import $ from "jquery"
import {useDropzone} from 'react-dropzone'

import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

  import fileDownload from "js-file-download";

export const MassImport = () => {

    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
        setFile(acceptedFiles[0])

      }, [])
      const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

    const [file, setFile] = React.useState(null);



    const submitFile = () => {

        let formdata = new FormData();

        formdata.append('file', file);

        //check if a file has been selected
        if(!file){
            toast.info("Please select a file")
            return
        }

        toast.info("Importing...")
        toast.info("Large files may take a while, please wait...")
        fetch(`${process.env.REACT_APP_BASE_URL}upload`, {
            method: 'POST',
            body: formdata,
            credentials: 'include',
            redirect: 'follow'
        })
        .then(response => response.json())
        .then(data => {
            if(data.success){
                toast.success("Import successful")
            }else{
                toast.error("Import failed")
                toast.error(data.message)
            }
        }
        )
    }

    const downloadExample = () =>{
        fetch(`${process.env.REACT_APP_BASE_URL}exampleSheet`, {
            method: 'GET',
            credentials: 'include',
            redirect: 'follow'
        })
        .then(response => response.blob())
        .then(data => {
            fileDownload(data, "example.xlsx")
        }
        )
    }

   

    return (

        <body class="templete invenroty_page Inventory_expanded_details_page">

        <div class="main_content">

            <div class="main_content_lg">
                <div class="common_content_pad_block">
                    <div class="header_wrapper">
                        <div class="header_wrap">
                            <div class="menu_trigger ">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16.842" height="14.677" viewBox="0 0 16.842 14.677">
                                    <g id="Close_Menu" data-name="Close Menu" transform="translate(0.5 0.707)">
                                    <path id="Shape" d="M-5.526.5H0" transform="translate(15.842 12.503)" fill="none" stroke="#535776" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
                                    <path id="Shape-2" data-name="Shape" d="M0,0-6.263,6.632,0,13.263" transform="translate(6.263)" fill="none" stroke="#535776" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
                                    <path id="Shape-3" data-name="Shape" d="M-15.842,0H0" transform="translate(15.842 6.563)" fill="none" stroke="#535776" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
                                    <path id="Shape-4" data-name="Shape" d="M-5.526.5H0" transform="translate(15.842 -0.368)" fill="none" stroke="#535776" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
                                    </g>
                                </svg>
                            </div>
                            <div class="common_heading">
                                <h1 class="header_title">Mass Import</h1>
                                <p class="header_content">Import your current inventory here</p>
                            </div>

                            
                        </div>
                    </div>

                    <div class="inner_content_wrapper dashboard_one_content">
                        <div class="cm_form_wrap">
                           
                            
                            
                            
                        </div>

                        <div>
      <div className="container" style={{textAlign: 'center',
padding: '5%',
border: '3px #7F53F3 solid',
width: '60%',
margin: 'auto'}}>
        
                    <div {...getRootProps()} style={{border: '3px #7F53F3 solid',padding:"5%"}}>
                        <input {...getInputProps()} />
                        {
                            isDragActive ?
                            <p>Drop the files here ...</p> :
                            <p>Drag and drop some files here, or click to select files</p>
                        }
                        </div>
                        <br></br>
                        <p>{file? file.name: null}</p>
                        <br></br>
                         <button class="cm_btn web_btn" onClick={()=>submitFile()}>Upload</button>

      </div>
    </div>
                        <div className="container" style={{textAlign: 'center',
padding: '5%',
width: '60%',
margin: 'auto'}}>
                            <p>Need the template?</p>
    <button class="cm_btn web_btn" onClick={()=>downloadExample()}>Download</button>
    </div>
                        {/* <div class="inventory_table_wrap">
                            <div class="table-responsive cm_table_responsive ">
                                <div class="tbl-header"></div>
                                
                                </div>
                                
                            </div> */}
                        </div>

                    </div>
                    <ToastContainer></ToastContainer>
              </div>
          </div>
          
          </body>

    //     </div>

    // </div>

    

    

    // <div class="modal fade" id="exampleModal_2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    //     <div class="modal-dialog modal-dialog-centered">
    //     <div class="modal-content">
    //         <div class="modal-header">
    //             <div class="modal_header_content">
    //                 <h5 class="modal-title" id="exampleModalLabel">Override listing / sale details</h5>
    //                 <p>Only override if you are listing somewhere other than viagogo or stubhub. Contact us if you are experiencing issues with information coming from viagogo/stubhub</p>
    //             </div>
    //         <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    //             <path d="M5.00098 5L19.0005 18.9996" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    //             <path d="M4.99947 18.9996L18.999 5" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    //             </svg>
    //             </button>
    //         </div>
    //         <div class="modal-body">
    //             <div class="modal_body_details">
    //                 <form class="modal_form">
    //                     <div class="cm_form_wrap">
    //                         <div class="cm_col_6">
    //                             <div class="cm_date_box modal_cm_width height_76">
    //                                 <label class="form">Listed?</label>
    //                                 <div class="radio_button">
    //                                     <div class="form_group">
    //                                         <input type="radio" id="yes" name="gender" checked />
    //                                         <label for="yes">Yes</label>
    //                                       </div>
                                        
    //                                       <div class="form_group">
    //                                         <input type="radio" id="No" name="gender" />
    //                                         <label for="No">No</label>
    //                                       </div>
    //                                 </div>
    //                             </div>

    //                             <div class="cm_date_box modal_cm_width">
    //                                 <label>Proceeds Per Ticket</label>
    //                                 <div class="input_wrap">
    //                                     <input type="text">
    //                                 </div>
    //                             </div>

    //                             <div class="cm_date_box modal_cm_width">
    //                                 <label>Seats</label>
    //                                 <div class="input_wrap">
    //                                     <input type="text">
    //                                 </div>
    //                             </div>
    //                         </div>

    //                         <div class="cm_col_6">
    //                             <div class="cm_date_box modal_cm_width">
    //                                 <label>Site Listed On</label>
    //                                 <div class="input_wrap">
    //                                     <input type="text">
    //                                 </div>
    //                             </div>
    //                             <div class="cm_date_box modal_cm_width">
    //                                 <label>Tickets Sold</label>
    //                                 <div class="input_wrap">
    //                                     <input type="text">
    //                                 </div>
    //                             </div>
    //                             <div class="cm_date_box modal_cm_width">
    //                                 <label>Status</label>
    //                                 <div class="input_wrap">
    //                                     <input type="text">
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </form>
    //             </div>
    //             <div class="button_wraper text-center"> 
    //                 <button class="cm_btn web_btn">Save</button>
    //                 <button class="cm_btn web_btn grey_color">Cancel</button>
    //             </div>
    //         </div>
    //     </div>
    //     </div>
    // </div>
    )
}