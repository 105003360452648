import React from 'react'

import { ToastContainer, toast } from 'react-toastify';


import 'react-toastify/dist/ReactToastify.css';



export const AddEmail = ({callback}) => {

    // const [listed, setListed] = React.useState("");
    // const [price, setProceedsPerTicket] = React.useState("");
    // const [platformListed, setSiteListedOn] = React.useState("");
    const [email, setEmail] = React.useState("");
    // const [status, setStatus] = React.useState("");

    const handleEdit = () => {
        const purchase = {  email};


        let wrongFields = []
        //validate every field
        for (const i in purchase) {
            if (purchase[i] === '' && i === "email") {
                wrongFields.push(i)

                continue
            }
        }

        

        if (wrongFields.length > 0) {
            toast("Please fill in all fields")
            return
        }

        fetch(`${process.env.REACT_APP_BASE_URL}addWhitelistedEmail`, {
            credentials: 'include',
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(purchase)
        }).then(() => {
            toast.success("Sale added successfully")
            callback( email)

        })
    }

    return (

        <div class="modal fade" id="exampleModal_10" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="modal_header_content">
                            <h5 class="modal-title" id="exampleModalLabel">Add Whitelisted Email</h5>
                            <p>Add an email here</p>
                        </div>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.00098 5L19.0005 18.9996" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M4.99947 18.9996L18.999 5" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="modal_body_details">
                            <form class="modal_form">
                                <div class="cm_form_wrap">
                                <div class="cm_col_6">
                                        

                                        <div class="cm_date_box modal_cm_width">
                                            <label>Email</label>
                                            <div class="input_wrap">
                                                <input type="text" value={email} onChange={e => setEmail(e.target.value)} />
                                            </div>
                                        </div>

                                        




                                    </div>


                                    
                                </div>
                            </form>
                        </div>
                        <div class="button_wraper text-center">
                            <button class="cm_btn web_btn" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleEdit()}>Add</button>
                            <button class="cm_btn web_btn grey_color" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                        </div>
                    </div>


                </div>
                <ToastContainer />
            </div>
        </div>
    )
}


