export function formatDate(date) {
    const months = [
      'JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN',
      'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'
    ];
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = months[date.getMonth()];
    const year = date.getFullYear().toString();
    return `${day} ${month} ${year}`;
  }

export function formatGraphDate(date, mode){
  mode = mode.toLowerCase();
    const months = [
        'JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN',
        'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'
      ];
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = months[date.getMonth()];
    const year = date.getFullYear().toString();
    if(mode === 'day'){
        return `${day} ${month} ${year}`;
    }else if(mode === 'month'){
        return `${month} ${year}`;
    }else if(mode === 'year'){
        return `${year}`;
    }
}

export function getMonthNameFromNumber(number){
    const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
    return monthNames[number-1];
}
  