import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import { useEffect, useState } from 'react'
import { useContext } from "react"
import { AppContext } from "../../context"
import { useLocalStorage } from 'usehooks-ts'
import { getMonthNameFromNumber } from '../Util/date'
import { AddEvent } from './addEvent'

export const Calendar = () =>{
    const {events, setEvents} = useContext(AppContext)

    const [expectedPayout, setExpectedPayout] = useState(0)

    const [currency, setCurrency] = useLocalStorage("currency", "GBP")

    const [currentMonth, setCurrentMonth] = useState(1)

    const formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: currency,
    });

    

    function renderEventContent(eventInfo) {

        console.log(eventInfo.event._def.extendedProps.sale)
        console.log(eventInfo.event._def.extendedProps.sale[0].seat);
    return (
        <>
        <div class="fc-daygrid-event-dot"></div>
        <p style={{
            fontSize: "14px",
            fontWeight: "bold",
            color: "black",
            cursor: "pointer"
        }}>{eventInfo.event.title} - </p>
        <p style={{
            fontSize: "14px",
            fontWeight: "bold",
            color: "black",
            cursor: "pointer"
        }}>{eventInfo.event._def.extendedProps.sale[0] ? formatter.format(eventInfo.event._def.extendedProps.sale[0].price) : null} </p>
        <p style={{
            fontSize: "14px",
            fontWeight: "bold",
            color: "black",
            cursor: "pointer"
        }}> &nbsp;&nbsp;|&nbsp; {eventInfo.event._def.extendedProps.status}</p>
        <p style={{
            fontSize: "14px",
            fontWeight: "bold",
            color: "black",
            cursor: "pointer"
        }}> &nbsp;&nbsp;|&nbsp; {
            Array.isArray(eventInfo.event._def.extendedProps.sale[0].seat)
            ? eventInfo.event._def.extendedProps.sale[0].seat.join(', ')
            : eventInfo.event._def.extendedProps.sale[0].seat
        }</p>
        </>
    )

    
}



    useEffect(() => {
        if(events.length ===0){
        fetch(`${process.env.REACT_APP_BASE_URL}getEvents?currency=${currency}`, {
            method: "GET",
            credentials: "include",
            redirect: 'follow',
            headers: {
                "Content-Type": "application/json",

            }
        }).then(res => res.json()).then(data => {
            if (data.success ) {
                setEvents(data.events)
            } else {
                alert(data.message);
            }
        })

        
    }
    }, [])

       function findMiddleYearAndMonth(time1, time2) {
        const date1 = new Date(time1);
        const date2 = new Date(time2);
        
        const timeDiff = date2 - date1;
        const middleTimestamp = date1.getTime() + (timeDiff / 2);
        
        const middleTime = new Date(middleTimestamp);
        const middleYear = middleTime.getFullYear();
        const middleMonth = middleTime.getMonth() + 1; // Adding 1 because getMonth() is 0-indexed

        let filteredEvents = events.filter(event => {
            const eventDate = new Date(event.date)
            return eventDate.getFullYear() === middleYear && eventDate.getMonth() + 1 === middleMonth && (event.status.includes("Sold") || event.status.includes("Payment"))
        })

        setExpectedPayout(filteredEvents.reduce((a, b) => a + b.sale[0].price, 0))
        setCurrentMonth(middleMonth)
  
        return { year: middleYear, month: middleMonth };
    }

    



    return (
        <>
        <div class="main_content">

            <div class="main_content_lg">
                <div class="common_content_pad_block">
                    <div class="header_wrapper">
                        <div class="header_wrap">
                            <div class="menu_trigger ">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16.842" height="14.677" viewBox="0 0 16.842 14.677">
                                    <g id="Close_Menu" data-name="Close Menu" transform="translate(0.5 0.707)">
                                    <path id="Shape" d="M-5.526.5H0" transform="translate(15.842 12.503)" fill="none" stroke="#535776" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
                                    <path id="Shape-2" data-name="Shape" d="M0,0-6.263,6.632,0,13.263" transform="translate(6.263)" fill="none" stroke="#535776" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
                                    <path id="Shape-3" data-name="Shape" d="M-15.842,0H0" transform="translate(15.842 6.563)" fill="none" stroke="#535776" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
                                    <path id="Shape-4" data-name="Shape" d="M-5.526.5H0" transform="translate(15.842 -0.368)" fill="none" stroke="#535776" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
                                    </g>
                                </svg>
                            </div>
                            <div class="common_heading">
                                <h1 class="header_title">Calendar</h1>
                                <p class="header_content">View which events are coming up that you have tickets for. Use this to estimate when you're going to be paid (payments are generally made ~5 days after the event).</p>
                            </div>
                        </div>
                    </div>

                    <div class="inner_content_wrapper dashboard_one_content">
                         <div class="header_button_wrap">
                                    <div class="button_wraper"><button class="cm_btn web_btn" data-bs-toggle="modal" data-bs-target="#eventModal"


                                    >Add Event</button></div>
                                </div>
                                <br></br>
 
                        <div class="calendar_wrap">
                            <div id='calendar'>

                            <FullCalendar
                                plugins={[ dayGridPlugin ]}
                                initialView="dayGridMonth"
                                initialDate={new Date().toISOString().slice(0, 10)}
                                events={events}
                                eventContent={renderEventContent}
                                datesSet={(arg) => {
                                    console.log(findMiddleYearAndMonth(arg.startStr, arg.endStr))
                                }}
                                
                                
                            />
                            </div>

                            <div class="previous_payouts">
                                <div class="previous_payouts_inner">
                                    <div class="previous_title">
                                        <h2>Expected {getMonthNameFromNumber(currentMonth)} Payout:</h2>
                                        <p></p>
                                    </div>

                                    <div class="previous_title">
                                        <h3 style={{textAlign:"center"}}>{formatter.format(expectedPayout)}</h3>
                                        <p></p>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
              </div>
          </div>

        </div>

        <AddEvent></AddEvent>
        </>

    )

}