
import "../css/responsive.css"
import { useState,useEffect } from "react";
import { AddExpense } from "./addExpense";
import { formatDate } from "../Util/date";
import { DeleteExpense } from "./deleteExpense";
import { useLocalStorage } from "usehooks-ts"
import '../js/custom.js'
import { useContext } from "react"
import { AppContext } from "../../context"


export const Expenses = () => {

    const {expenses,setExpenses} = useContext(AppContext)

    const [id, setId] = useState(0)

    const [totalExpenses, setTotalExpenses] = useState(0)

    const [allTimeExpenses, setAllTimeExpenses] = useState(0)

    // const [expenses, setExpenses] = useState([])

    const [currency,setCurrency] =useLocalStorage("currency","GBP")

    const formatter = new Intl.NumberFormat("en-GB",{
        style: "currency",
        currency:currency,
        minimumFractionDigits: 2
    })

   


    useEffect(() => {

        if(expenses.length ===0){
        let thisMonth = new Date().getMonth() + 1
        fetch(`${process.env.REACT_APP_BASE_URL}viewExpenses?currency=${currency}`, {
            method: "GET",
            credentials: "include",
            redirect: 'follow',
            headers: {
                "Content-Type": "application/json",

            }
        }).then(res => res.json()).then(data => {
            if (data.success) {
                setExpenses(data.expenses)
                if(data.expenses.length>0){
                    let thisMonthsExpneses = data.expenses.filter(expense => new Date(expense.date).getMonth() + 1 === thisMonth)
                    setTotalExpenses(thisMonthsExpneses.reduce((a, b) => a + b.amount, 0))
                    setAllTimeExpenses(data.expenses.reduce((a, b) => a + b.amount, 0))
                }
            } else {
                alert(data.message);
            }
        })
    }else{
        let thisMonth = new Date().getMonth() + 1
        let thisMonthsExpneses = expenses.filter(expense => new Date(expense.date).getMonth() + 1 === thisMonth)
        setTotalExpenses(thisMonthsExpneses.reduce((a, b) => a + b.amount, 0))
        setAllTimeExpenses(expenses.reduce((a, b) => a + b.amount, 0))
    }
    }, [])

    useEffect(() => {
        let thisMonth = new Date().getMonth() + 1
        if(expenses.length>0){
            
            let thisMonthsExpneses = expenses.filter(expense => new Date(expense.date).getMonth() + 1 === thisMonth)
            setTotalExpenses(thisMonthsExpneses.reduce((a, b) => a + b.amount, 0))
            setAllTimeExpenses(expenses.reduce((a, b) => a + b.amount, 0))
        }
    }, [expenses])


   




    return (
        <body class="templete invenroty_page expenses_page sidebar_reduced">
        <div class="main_content">

            <div class="main_content_lg">
                <div class="common_content_pad_block">
                    <div class="header_wrapper">
                        <div class="header_wrap">
                            <div class="menu_trigger ">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16.842" height="14.677" viewBox="0 0 16.842 14.677">
                                    <g id="Close_Menu" data-name="Close Menu" transform="translate(0.5 0.707)">
                                    <path id="Shape" d="M-5.526.5H0" transform="translate(15.842 12.503)" fill="none" stroke="#535776" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"></path>
                                    <path id="Shape-2" data-name="Shape" d="M0,0-6.263,6.632,0,13.263" transform="translate(6.263)" fill="none" stroke="#535776" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"></path>
                                    <path id="Shape-3" data-name="Shape" d="M-15.842,0H0" transform="translate(15.842 6.563)" fill="none" stroke="#535776" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"></path>
                                    <path id="Shape-4" data-name="Shape" d="M-5.526.5H0" transform="translate(15.842 -0.368)" fill="none" stroke="#535776" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"></path>
                                    </g>
                                </svg>
                            </div>
                            <div class="common_heading">
                                <h1 class="header_title">Expenses Hub</h1>
                                <p class="header_content">Manage and track your non-ticket expenses</p>
                            </div>

                            <div class="header_button_wrap">
                                <div class="button_wraper"><button class="cm_btn web_btn" data-bs-toggle="modal" data-bs-target="#exampleModal">Add Expense</button></div>
                            </div>
                        </div>
                    </div>

                    <div class="inner_content_wrapper dashboard_one_content">
                        <div style={{display:"flex"}}>
                        <div class="monthly_expenses_wrap">
                            <p>This months Expenses:</p>
                            <h3>{formatter.format(totalExpenses)}</h3>
                        </div>
                        <div class="monthly_expenses_wrap">
                            <p>Total Expenses:</p>
                            <h3>{formatter.format(allTimeExpenses)}</h3>
                        </div>
                        </div>

                        <div class="inventory_table_wrap">
                            <div class="table-responsive cm_table_responsive ">
                                <div class="tbl-header"></div>
                                    <table class="cm_table fold-table">
                                        <thead class="cm_thead">
                                            <tr class="cm_thead_list">
                                                <th class="th_list cm_name"><div class="th_item_wrap"><h6>Event</h6></div></th>
                                                <th class="th_list cm_date"><div class="th_item_wrap"><h6>Amount</h6></div></th>
                                                <th class="th_list cm_tickets"><div class="th_item_wrap"><h6>Date</h6></div></th>                                                
                                            </tr>
                                        </thead>
                                     {/* </table>
                                </div> 
                                 <div class="tbl-content">
                                    <table class="cm_table"> */}
                                        <tbody class="cm_tbody">


                                            {expenses.map((expense) => (
                                                <>
                                            <tr class="cm_tr">
                                                <td class="cm_td"><h4>{expense.name}</h4></td>
                                                <td class="cm_td"><h4>{formatter.format(Number(expense.amount).toFixed(2))}</h4></td>
                                                <td class="cm_td"><h4>{formatDate(new Date(expense.date))}</h4></td>
                                                <td class="cm_td"><button class="deleterow" onClick={()=>setId(expense.id)} type="submit"><svg width="20" height="20" viewBox="0 0 20 20" data-bs-toggle="modal" data-bs-target="#exampleModal4" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.5 5H4.16667H17.5" stroke="#EF5350" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M15.8332 5.00008V16.6667C15.8332 17.1088 15.6576 17.5327 15.345 17.8453C15.0325 18.1578 14.6085 18.3334 14.1665 18.3334H5.83317C5.39114 18.3334 4.96722 18.1578 4.65466 17.8453C4.3421 17.5327 4.1665 17.1088 4.1665 16.6667V5.00008M6.6665 5.00008V3.33341C6.6665 2.89139 6.8421 2.46746 7.15466 2.1549C7.46722 1.84234 7.89114 1.66675 8.33317 1.66675H11.6665C12.1085 1.66675 12.5325 1.84234 12.845 2.1549C13.1576 2.46746 13.3332 2.89139 13.3332 3.33341V5.00008" stroke="#EF5350" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/><path d="M8.3335 9.16675V14.1667" stroke="#EF5350" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/><path d="M11.6665 9.16675V14.1667" stroke="#EF5350" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/></svg></button></td>

                                                
                                            </tr>
                                            <br></br>
                                            </>
                                            ))}
                                            
                                        </tbody>
                                    </table>
                                </div>
                                
                            </div>
                        </div>

                    </div>
              </div>
          </div>
          <AddExpense expense={expenses} setExpenses={setExpenses} ></AddExpense>
          <DeleteExpense id={id} expenses={expenses} setExpenses={setExpenses} ></DeleteExpense>
          </body>

    )
    
}