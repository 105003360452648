

import React from 'react';
import { toast } from 'react-toastify';

export const EditSettings =({name,callback}) =>{
    const [editName, setEditName] = React.useState(name);

    const editSettings = ()=>{

        fetch(`${process.env.REACT_APP_BASE_URL}editname`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
                name: editName
            })
        })

       

        toast.success("Name Edited", {
            position: toast.POSITION.TOP_RIGHT
        });

        callback(editName)

    }


    return (
        <>
        <div class="modal fade" id="exampleModal5" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="modal_header_content">
                        <h5 class="modal-title" id="exampleModalLabel">Edit Account</h5>
                    </div>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.00098 5L19.0005 18.9996" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M4.99947 18.9996L18.999 5" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="modal_body_details">
                        <form class="modal_form" >
                            <div class="cm_form_wrap">
                                <div class="cm_col_6">
                                    <div class="cm_date_box modal_cm_width">
                                        <label class="form">Name</label>
                                        <div class="input_wrap">
                                            <input type="text" value={editName} onChange={e => setEditName(e.target.value)}/>
                                        </div>
                                    </div>
                                    
                                </div>
                               
                            </div>
                        </form>
                    </div>
                    <div class="button_wraper"> 
                        <button class="cm_btn web_btn" data-bs-dismiss="modal" aria-label="Close" onClick={() => editSettings()}>Save</button>
                        <button class="cm_btn web_btn grey_color" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                    </div>
                </div>
            </div>
            </div>
        </div>
        </>
    )
}