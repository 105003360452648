import DatePicker from "react-datepicker";
import React from "react";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import "react-datepicker/dist/react-datepicker.css";
import { currencies } from "../../constants/constants";

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles, createStyles } from '@material-ui/core/styles';



export const EditSale = ({ sale,callback }) => {


    let loadingDate = new Date(sale.date).toString() === "Invalid Date" ? new Date() : new Date(sale.date)
    const [name, setName] = React.useState(sale.eventName);
    const [startDate, setStartDate] = React.useState(loadingDate);
    const [location, setLocation] = React.useState(sale.location);
    const [section, setSection] = React.useState(sale.section);
    const [row, setRow] = React.useState(sale.row);
    const [seats, setSeats] = React.useState(sale.seats);
    
    
    const [tickets, setTickets] = React.useState(sale.tickets);
    const [priceInclFees, setPriceInclFees] = React.useState(sale.priceInclFees);
    const [orderNumber, setOrderNumber] = React.useState(sale.orderNumber);
    const [emailUsed, setEmailUsed] = React.useState(sale.emailUsed);


    React.useEffect(() => {
        setName(sale.name)
        setStartDate(loadingDate)
        setSection(sale.section)
        setRow(sale.row)
        setSeats(sale.seat)
        setTickets(sale.quantity)
        setPriceInclFees(sale.price)
        setOrderNumber(sale.orderNumber)
        setEmailUsed(sale.email)
    }, [sale])



 const handleSeatChange = (e) => {
        if (e.target.value.includes(',')) {
            let seats = e.target.value.split(',')
            setSeats(seats)
        } else {
            setSeats([e.target.value])
        }
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            name: name,
            date: startDate,
            location: location,
            section: section,
            row: row,
            seats: seats,
            tickets: tickets,
            priceInclFees: priceInclFees,
            orderNumber: orderNumber,
            emailUsed: emailUsed,
            id: sale.id
        }
        
        fetch(`${process.env.REACT_APP_BASE_URL}editSale`,{
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),

        }).then(res => res.json())
        .then(data => {
            if (data.status === 200) {
                toast.success("Sale Edited!")
                callback(data)
            } else {
                toast.error(data.message)
            }
        })
       
    };
   


    return (

        <>
            <div class="modal fade" id="exampleModal12" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div class="modal_header_content">
                                <h5 class="modal-title" id="exampleModalLabel">Edit your automatically matched sale</h5>
                            </div>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.00098 5L19.0005 18.9996" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M4.99947 18.9996L18.999 5" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="modal_body_details">
                                <form class="modal_form" >
                                    <div class="cm_form_wrap">
                                        <div class="cm_col_6">
                                            <div class="cm_date_box modal_cm_width">
                                                <label class="form">Event Name</label>
                                                <div class="input_wrap">
                                                    <input type="text" placeholder="Ricky Gervais" value={name} onChange={e => setName(e.target.value)} />
                                                </div>
                                            </div>
                                            <div class="cm_date_box modal_cm_width">
                                                <label>Event Date</label>
                                                <div class="input_wrap">
                                                    <DatePicker selected={startDate}
                                                        dateFormat="d MMMM yyyy"
                                                        popperPlacement="top-start"
                                                        onChange={date => setStartDate(date)}
                                                    />
                                                </div>
                                            </div>
                                            
                                           
                                            <div class="cm_date_box modal_cm_width">
                                                <label>Section</label>
                                                <div class="input_wrap">
                                                    <input type="text" placeholder="B" value={section} onChange={e => setSection(e.target.value)} />
                                                </div>
                                            </div>
                                            <div class="cm_date_box modal_cm_width">
                                                <label>Row</label>
                                                <div class="input_wrap">
                                                    <input type="text" placeholder="H" value={row} onChange={e => setRow(e.target.value)} />
                                                </div>
                                            </div>
                                            <div class="cm_date_box modal_cm_width">
                                                <label>Seats</label>
                                                <div class="input_wrap">
                                                    <input type="text" placeholder="04,05" value={seats} onChange={e => handleSeatChange(e)} />
                                                </div>
                                            </div>

                                        </div>


                                        <div class="cm_col_6">
                                            
                                            <div class="cm_date_box modal_cm_width">
                                                <label>Tickets</label>
                                                <div class="input_wrap">
                                                    <input type="number" placeholder="2" value={tickets} onChange={e => setTickets(parseInt(e.target.value))} />
                                                </div>
                                            </div>
                                            <div class="cm_date_box modal_cm_width">
                                                <label>Total Price</label>
                                                <div class="input_wrap">
                                                    <input type="number" placeholder="£99.99 (intl format)" value={priceInclFees} onChange={e => setPriceInclFees(parseFloat(e.target.value))} />
                                                </div>
                                            </div>

                                            <div class="cm_date_box modal_cm_width">
                                                <label>Order Number</label>
                                                <div class="input_wrap">
                                                    <input type="text" placeholder="55-50752/UK1" value={orderNumber} onChange={e => setOrderNumber(e.target.value)} />
                                                </div>
                                            </div>

                                            <div class="cm_date_box modal_cm_width">
                                                <label>Email Used</label>
                                                <div class="input_wrap">
                                                    <input type="text" placeholder="hello@trackd.gg" value={emailUsed} onChange={e => setEmailUsed(e.target.value)} />
                                                </div>
                                            </div>

                                          

                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="button_wraper">
                                <button class="cm_btn web_btn" data-bs-dismiss="modal" aria-label="Close" onClick={(e)=> handleSubmit(e)}>Save</button>
                                <button class="cm_btn web_btn grey_color" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                            </div>
                        </div>
                        <ToastContainer />
                    </div>
                </div>
            </div>
        </>
    )
}