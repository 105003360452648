import * as React from "react";
import { Routes, Route, Outlet, Link, useLocation } from "react-router-dom";
import { Home } from "./components/homepage/home";
import { SidebarLayout } from "./components/sidebar/sidebarlayout";
import { Settings } from "./components/settings/settings";
import { Calendar } from "./components/calendar/calendar";
import { Login } from "./components/login/login";
import { Signup } from "./components/signup/signup";
import { Expenses } from "./components/expenses/expenses";
import { Activities } from "./components/activities/activites";
import { NotFound } from "./components/notFound/notFound";
import { Privacy } from "./components/policies/privacy";
import { Terms } from "./components/policies/terms";
import { Email } from "./components/email/email";
import { Inventory } from "./components/inventory/inventory";
import { Dashboard } from "./components/dashboard/dashboard";
import { MassImport } from "./components/import/massImport";
import { ForgotPassword } from "./components/forgotPassword/forgotpassword";
import { ChangePassword } from "./components/changePassword/changepassword";

import "./components/css/nice-select.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./components/css/google-font.css";
import "./components/css/style.css";
import "./components/css/responsive.css";
import "bootstrap/dist/js/bootstrap.js";
// import './components/js/custom.js'
import { useState } from "react";
// import "./components/css/main.min.css"
import CookieConsent from "react-cookie-consent";
import { useLocalStorage } from "usehooks-ts";
import { AppContext } from "./context";
import { Callback } from "./components/callback/callback";

export default function App() {
  //global inventory state to improve performance
  const [inventory, setInventory] = useState([]);
  const [user, setUser] = useState({});
  const [expenses, setExpenses] = useState([]);
  const [dashboard, setDashboard] = useState([]);
  const [events, setEvents] = useState([]);
  const [emaillist, setEmaillist] = useState([]);
  const [graphData, setGraphData] = useState({});

  const loadGA = () => {
    const script = document.createElement("script");
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-T0KFLP0CWD";
    script.async = true;
    document.body.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "G-T0KFLP0CWD", {
      page_path: window.location.pathname,
    });
  };

  if (document.getElementById("custom")) {
    document.getElementById("custom").remove();
    const script = document.createElement("script");
    script.src = "/custom.js";
    script.id = "custom";
    document.body.appendChild(script);
  } else {
    const script = document.createElement("script");
    script.src = "/custom.js";
    script.id = "custom";
    document.body.appendChild(script);
  }

  const [cookie, setCookie] = useLocalStorage("cookie", false);

  React.useEffect(() => {
    //load google analytics if cookie is there

    if (cookie) {
      loadGA();
    }
  }, [cookie]);

  let location = useLocation();

  React.useEffect(() => {}, [location]);

  return (
    <AppContext.Provider
      value={{
        inventory,
        setInventory,
        user,
        setUser,
        expenses,
        setExpenses,
        dashboard,
        setDashboard,
        events,
        setEvents,
        emaillist,
        setEmaillist,
        graphData,
        setGraphData,
      }}
    >
      <div>
        <Routes>
          <Route path="app" element={<SidebarLayout />}>
            <Route path="dashboard" element={<Dashboard />}></Route>
            <Route path="settings" element={<Settings />} />
            <Route path="calendar" element={<Calendar />} />
            <Route path="expenses" element={<Expenses />} />
            <Route path="activities" element={<Activities />} />
            <Route path="email" element={<Email />} />
            <Route path="import" element={<MassImport />} />
            <Route
              path="inventory"
              element={
                <Inventory inventory={inventory} setInventory={setInventory} />
              }
            />
            <Route path="*" element={<NotFound />} />
          </Route>

          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/callback" element={<Callback />} />
          {/* <Route path="/signup" element={<Signup/>} /> */}
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/account/passwordreset" element={<ChangePassword />} />
        </Routes>
        <CookieConsent
          enableDeclineButton
          onAccept={() => {
            setCookie(true);
          }}
          buttonStyle={{
            background: "#9d81e4",
          }}
          onDecline={() => {
            setCookie(false);
          }}
        >
          This website uses cookies to enhance the user experience.
        </CookieConsent>
      </div>
    </AppContext.Provider>
  );
}
