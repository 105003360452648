import DatePicker from "react-datepicker";
import React from "react";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import "react-datepicker/dist/react-datepicker.css";
import { currencies } from "../../constants/constants";

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { convertEuropeanNumber } from "../Util/EUnumberFormatter";

const useStyles = makeStyles({
    
    input: {
        width: '100%',
        right: '20px',
        "&.Mui-focused": {
          border: "none",
          
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
            borderRadius: 'none'
          },
        '& .MuiAutocomplete-root':{
            width:"100%"
          }
         
      }
});


export const AddPurchase = ({ inventory, setInventory }) => {


    const [name, setName] = React.useState('');
    const [date, setStartDate] = React.useState(new Date());
    const [purchaseDate, setPurchaseDate] = React.useState(new Date());
    const [location, setLocation] = React.useState('');
    const [section, setSection] = React.useState('');
    const [row, setRow] = React.useState('');
    const [seats, setSeats] = React.useState([]);
    const [retailer, setRetailer] = React.useState('');
    const [quantity, setTickets] = React.useState('');
    const [price, setPriceInclFees] = React.useState('');
    const [orderNumber, setOrderNumber] = React.useState('');
    const [email, setEmailUsed] = React.useState('');
    const [ticketType, setTicketType] = React.useState('')
    const [currency, setCurrency] = React.useState('GBP')
    const [paymentMethod, setPaymentMethod] = React.useState('')

    const retailerOptions = ["AXS", "gigsAndTours", "See", "Ticketmaster"];
    const typeOptions = ["E-Ticket", "Mobile", "Paper"];
    const emailOptions = [window.sessionStorage.getItem("email")];


    const classes = useStyles();

    const handleSeatChange = (e) => {
        if (e.target.value.includes(',')) {
            let seats = e.target.value.split(',')
            setSeats(seats)
        } else {
            setSeats([e.target.value])
        }
    }

    

    const handleSubmit = (e) => {
        e.preventDefault();
        const purchase = { name, date, location, section, row, seats, retailer, quantity, price, orderNumber, email, ticketType, purchaseDate, currency, paymentMethod };


        let wrongFields = []
        //validate every field
        for (const i in purchase) {
            if (purchase[i] === '') {

                if (i === 'name' || i === 'location' || i === 'date' || i === 'section') {
                    wrongFields.push(i)
                }

                continue
            }
        }

        console.log(purchase)

        purchase.price = convertEuropeanNumber(purchase.price.toString())
        

        const newPurchase = {
            addedMethod: "manual",
            date: purchase.date.toString(),
            email: purchase.email,
            id: 45,
            listed: false,
            listingId: "N/A",
            location: purchase.location,
            name: purchase.name,
            orderNumber: purchase.orderNumber,
            price: purchase.price,
            purchaseDate: purchase.purchaseDate.toString(),
            quantity: purchase.quantity,
            retailer: purchase.retailer,
            row: purchase.row,
            seat: purchase.seats,
            ticketType: purchase.ticketType,
            section: purchase.section,
            status: "Not Listed",
            currency: purchase.currency,
            paymentMethod: purchase.paymentMethod,
            profit: 0,
            estValue: 0,
            sale: [{
                currency: purchase.currency, date: purchase.date, email: purchase.email, id: 19, name: purchase.name, orderNumber: "N/A", price: 0, purchaseId: 0, quantity: purchase.quantity, row: purchase.row, seat: purchase.seats, section: purchase.section, userId: 4, quantitySold: 0
            }]

        }

        if (wrongFields.length > 0) {
            toast("Please fill in all fields")
            return
        }

        fetch(`${process.env.REACT_APP_BASE_URL}addManualPurchase`, {
            credentials: 'include',
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(purchase)
        }).then((data) => {
            data.json().then((data) => {
                newPurchase.id = data.id
                newPurchase.sale[0].purchaseId = data.saleId
                toast.success("Purchase added")
                setInventory([...inventory, newPurchase])
            })


        })
    }



    return (

        <>
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div class="modal_header_content">
                                <h5 class="modal-title" id="exampleModalLabel">Manually add a purchase </h5>
                                <p>Please do not manually add purchases you have forwarded to you ticketbox email as this will result in duplication</p>
                            </div>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.00098 5L19.0005 18.9996" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M4.99947 18.9996L18.999 5" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="modal_body_details">
                                <form class="modal_form" >
                                    <div class="cm_form_wrap">
                                        <div class="cm_col_6">
                                            <div class="cm_date_box modal_cm_width">
                                                <label class="form">Event Name</label>
                                                <div class="input_wrap">
                                                    <input type="text" placeholder="Ricky Gervais" onChange={e => setName(e.target.value)} />
                                                </div>
                                            </div>
                                            <div class="cm_date_box modal_cm_width">
                                                <label>Event Date</label>
                                                <div class="input_wrap">
                                                    <DatePicker selected={date}
                                                        dateFormat="d MMMM yyyy"
                                                        popperPlacement="top-start"
                                                        onChange={date => setStartDate(date)}
                                                    />
                                                </div>
                                            </div>
                                            <div class="cm_date_box modal_cm_width">
                                                <label>Purchase Date</label>
                                                <div class="input_wrap">
                                                    <DatePicker selected={purchaseDate}
                                                        dateFormat="d MMMM yyyy"
                                                        popperPlacement="top-start"
                                                        onChange={date => setPurchaseDate(date)}
                                                    />
                                                </div>
                                            </div>
                                            <div class="cm_date_box modal_cm_width">
                                                <label class="form">Event Location</label>
                                                <div class="input_wrap">
                                                    <input type="text" placeholder="London" onChange={e => setLocation(e.target.value)} />
                                                </div>
                                            </div>
                                            <div class="cm_date_box modal_cm_width">
                                                <label>Section</label>
                                                <div class="input_wrap">
                                                    <input type="text" placeholder="B" onChange={e => setSection(e.target.value)} />
                                                </div>
                                            </div>
                                            <div class="cm_date_box modal_cm_width">
                                                <label>Row</label>
                                                <div class="input_wrap">
                                                    <input type="text" placeholder="H" onChange={e => setRow(e.target.value)} />
                                                </div>
                                            </div>
                                            <div class="cm_date_box modal_cm_width">
                                                <label>Seats</label>
                                                <div class="input_wrap">
                                                    <input type="text" placeholder="04,05" onChange={e => handleSeatChange(e)} />
                                                </div>
                                            </div>

                                        </div>


                                        <div class="cm_col_6">
                                            <div class="cm_date_box modal_cm_width">
                                                <label>Ticket Type </label>
                                                <div class="input_wrap">
                                                    <Autocomplete
                                                        disableClearable
                                                        freeSolo
                                                        className={classes.input}
                                                        options={typeOptions}
                                                        onChange={(event, value) => setTicketType(value)}
                                                        renderInput={(params) => <TextField {...params}  placeholder="E-Ticket" className={classes.input} onChange={(e)=>setTicketType(e.target.value)}/>}
                                                    />
                                                </div>
                                            </div>
                                            <div class="cm_date_box modal_cm_width">
                                                <label>Retailer</label>
                                                <div class="input_wrap">
                                                    <Autocomplete
                                                        
                                                        disableClearable
                                                        freeSolo
                                                        className={classes.input}
                                                       
                                                        
                                                        options={retailerOptions}
                                                        onChange={(event, value) => {console.log(value, event) 
                                                            setRetailer(value)}}
                                                        renderInput={(params) => <TextField {...params}  placeholder="Ticketmaster" className={classes.input} onChange={(e)=> setRetailer(e.target.value)} />}
                                                    />
                                                </div>
                                            </div>
                                            <div class="cm_date_box modal_cm_width">
                                                <label>Tickets</label>
                                                <div class="input_wrap">
                                                    <input type="number" placeholder="2" onChange={e => setTickets(parseInt(e.target.value))} />
                                                </div>
                                            </div>
                                            <div class="cm_date_box modal_cm_width">
                                                <label>Total Price</label>
                                                <div class="input_wrap">
                                                    <input type="number" placeholder="£99.99 (intl format)" onChange={e => setPriceInclFees(parseFloat(e.target.value))} />
                                                </div>
                                            </div>

                                            <div class="cm_date_box modal_cm_width">
                                                <label>Order Number</label>
                                                <div class="input_wrap">
                                                    <input type="text" placeholder="55-50752/UK1" onChange={e => setOrderNumber(e.target.value)} />
                                                </div>
                                            </div>

                                            <div class="cm_date_box modal_cm_width">
                                                <label>Email Used</label>
                                                <div class="input_wrap">
                                                    <Autocomplete
                                                        disableClearable
                                                        freeSolo
                                                        className={classes.input}
                                                        options={emailOptions}
                                                        onChange={(event, value) => setEmailUsed(value)}
                                                        renderInput={(params) => <TextField {...params}  placeholder="E-mail" className={classes.input} onChange={e=> setEmailUsed(e.target.value)} />}
                                                    />
                                                </div>
                                            </div>

                                            <div class="cm_date_box modal_cm_width">
                                                <label>Currency</label>
                                                <div class="input_wrap">
                                                    <select class="nice-select wide" style={{
                                                        paddingLeft: "0px",
                                                        width: "106%",
                                                        marginLeft: "-24px"
                                                    }} value={currency} onChange={e => setCurrency(e.target.value)}>
                                                        {currencies.map((currency) => (
                                                            <option data-display={currency}>{currency}</option>
                                                        ))}

                                                    </select>
                                                </div>
                                            </div>

                                            <div class="cm_date_box modal_cm_width">
                                                <label>Payment Method</label>
                                                <div class="input_wrap">
                                                    <input type="text" placeholder="PayPal" onChange={e => setPaymentMethod(e.target.value)} />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="button_wraper">
                                <button class="cm_btn web_btn" data-bs-dismiss="modal" aria-label="Close" onClick={e => handleSubmit(e)}>Save</button>
                                <button class="cm_btn web_btn grey_color" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                            </div>
                        </div>
                        <ToastContainer />
                    </div>
                </div>
            </div>
        </>
    )
}