import {useState,useEffect} from 'react';
import { EditSettings } from './editName';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocalStorage } from 'usehooks-ts';
import { currencies } from '../../constants/constants';
import { AddEmail } from '../email/addWhitelistEmail';
import { AppContext } from '../../context';
import { useContext } from 'react';
import fileDownload from "js-file-download";
import { DeleteAllPurchases } from "./clearInventory"






export const Settings = () => {

    const [name, setName] = useState('');

    const [Plan, setPlan] = useState('Basic');
 
    const [currency, setCurrency] = useLocalStorage('currency', 'GBP');
    const [whitelistedemails, setWhitelistedEmails] = useState([]);

    const cookieValue = document.cookie
                    .split("; ")
                    .find((row) => row.startsWith("token="))
                    ?.split("=")[1];

    const {user,setUser,setInventory, setDashboard,setExpenses, setGraphData,setEvents, inventory,dashboard,expenses} = useContext(AppContext)


    const editNameCallback = (name) => {
        setName(name)
        setUser({...user, name})
    }


    const changeCurrency = (currency)=>{
        setCurrency(currency)

        //reset inventory to force a refresh with the new currency

        setInventory([])
        setDashboard([])
        setExpenses([])
        setGraphData([])
        setEvents({})

        

    }


    const exportData = ()=>{
        toast.info("Exporting data, please wait...")
        fetch(`${process.env.REACT_APP_BASE_URL}exportSheet`, {
            credentials: 'include',
            method: 'GET',
            redirect: 'follow'
        }).then(response => response.blob())
        .then(data => {
            fileDownload(data, "trackd_export.xlsx")
        })
    }

    useEffect(() => {


        if(user.email === undefined){


        fetch(`${process.env.REACT_APP_BASE_URL}getSettings`,{
            credentials: 'include',
        })
            .then(res => res.json())
            .then(data => {
                setName(data.settings.name)
                setPlan(data.settings.level === 0 ? 'Basic' : 'Premium')
            })

        }

        fetch(`${process.env.REACT_APP_BASE_URL}getWhitelistedEmails`,{
            credentials: 'include',
            method: 'GET',
            redirect: 'follow'
        })
        .then(res => res.json())
        .then(data => {
            setWhitelistedEmails(data.emails)
        }
        )

    }, []);


      const deleteWhitelistedEmail = (email) => {
        fetch(`${process.env.REACT_APP_BASE_URL}deleteWhitelistedEmail`, {
            credentials: 'include',
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({email})
        }).then(() => {
            toast.success("Email deleted successfully")
            setWhitelistedEmails(whitelistedemails.filter((item) => item !== email))

        })
    }


    const whitelistCallback = (email) => {
        setWhitelistedEmails([...whitelistedemails, email])

    }

    return (
        <div class="main_content">

        <div class="main_content_lg">
            <div class="common_content_pad_block">
                <div class="header_wrapper">
                    <div class="header_wrap">
                        <div class="menu_trigger ">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16.842" height="14.677" viewBox="0 0 16.842 14.677">
                                <g id="Close_Menu" data-name="Close Menu" transform="translate(0.5 0.707)">
                                <path id="Shape" d="M-5.526.5H0" transform="translate(15.842 12.503)" fill="none" stroke="#535776" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
                                <path id="Shape-2" data-name="Shape" d="M0,0-6.263,6.632,0,13.263" transform="translate(6.263)" fill="none" stroke="#535776" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
                                <path id="Shape-3" data-name="Shape" d="M-15.842,0H0" transform="translate(15.842 6.563)" fill="none" stroke="#535776" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
                                <path id="Shape-4" data-name="Shape" d="M-5.526.5H0" transform="translate(15.842 -0.368)" fill="none" stroke="#535776" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
                                </g>
                            </svg>
                        </div>
                        <div class="common_heading">
                            <h1 class="header_title">Settings</h1>
                            <p class="header_content">Manage your account details, Integrations, and preferences</p>
                        </div>

                       

                        <div className="header_button_wrap">
                                
                                <div className="button_wraper"><button className="cm_btn web_btn" onClick={()=>exportData()}>Export</button></div>
                                
                            </div>
                    </div>
                </div>

                <div class="inner_content_wrapper dashboard_one_content">

                   <div class="setting_wrap">
                        <div class="cm_setting_block">
                            <div class="cm_setting_header">
                                <h2>Account Settings</h2>
                                <a href="#0"><img src="assets/images/frame.png" alt=""/></a>
                            </div>
                            <div class="cm_setting_body">
                                <div class="cm_media">
                                    <div class="cm_setting_content">
                                        <ul class="cm_setting_list">
                                            <li class="cm_setting_item"><p>Email</p><h6>{user.email}</h6></li>
                                            <li class="cm_setting_item"><p>Name</p><h6>{user.name}</h6></li>
                                        </ul>
                                        {/* <div class="cm_setting_button">
                                            <a href={`https://whop.com/oauth/?redirect_uri=https://api.trackd.gg/whop/callback&client_id=Vtg5J97gAhktboNpUnJAyeYrhptD5s1pKk-LuirYBZQ&state=${cookieValue}`} class="cm_setting_btn">Link Whop</a>
                                        </div> */}
                                        <div class="cm_setting_button">
                                            <a href="#0" class="cm_setting_btn" data-bs-toggle="modal" data-bs-target="#exampleModal5" >Edit Account Settings</a>
                                        </div>
                                    </div>
                                    <div class="cm_setting_content paddi_and_mar">
                                        <ul class="cm_setting_list">
                                            {/* <li class="cm_setting_item"><p>Username</p><h6>bendav371</h6></li> */}
                                            {/* <li class="cm_setting_item"><p>Discord</p><h6>{discordUsername}</h6></li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="cm_setting_block">
                            <div class="cm_setting_header">
                                <h2>Subscription</h2>
                            </div>
                            <div class="cm_setting_body">
                                <div class="cm_media">
                                    <div class="cm_setting_content paddi_and_mar">
                                        <ul class="cm_setting_list">
                                            <li class="cm_setting_item"><p>Plan</p><h6>{user.level === 0 ? "Beta": "Whop Member"}</h6></li>
                                        </ul>
                                        <div class="cm_setting_button">
                                            <a href="https://whop.com/track-d/" class="cm_setting_btn">Cancel/Mange Subscription</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="cm_setting_block">
                            <div class="cm_setting_header">
                                <h2>Wipe Inventory</h2>
                            </div>
                            <div class="cm_setting_body">
                            <div class="button_wraper"> <button class="cm_btn web_btn table_inventory_button" data-bs-toggle="modal" data-bs-target="#exampleModal7" >Wipe Inventory</button></div>

                            </div>
                        </div>

                        {/* <div class="cm_setting_block">
                            <div class="cm_setting_header">
                                <h2>Integrations</h2>
                            </div>
                            <div class="cm_setting_body">
                                <div class="cm_media">
                                    <div class="cm_setting_content">
                                        <ul class="cm_setting_list">
                                            <li class="cm_setting_item"><p>Viagogo</p><h6>{viagogoUsername}</h6><a href="#0" class="ml_20 cm_setting_btn">Disconnect</a></li>
                                        </ul>
                                    </div>
                                    <div class="cm_setting_content paddi_and_mar">
                                        <ul class="cm_setting_list">
                                            <li class="cm_setting_item"><p>Stubhub</p><h6>{stubhubUsername}</h6><a href="#0" class="ml_20 cm_setting_btn">Disconnect</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <div class="cm_setting_block">
                            <div class="cm_setting_header">
                                <h2>Preferences</h2>
                            </div>
                            <div class="cm_setting_body">
                                <div class="cm_form_wrap cm_setting_drop">
                                    {/* <div class="select_dropdown cm_width">
                                        <label class="cm_setting_label">Theme</label>
                                        <select class="wide">
                                            <option data-display="Light">Light</option>
                                            <option value="1">Light Item 1</option>
                                            <option value="2">Light Item 2</option>
                                            <option value="3">Light Item 3</option>
                                        </select>
                                    </div> */}

                                    <div class="select_dropdown cm_width">
                                        <label class="cm_setting_label">Currency</label>
                                        <div class="input_wrap">
                                        <select class="nice-select wide" style={{paddingLeft: "0px",
                                                                                width:"106%",
                                                                                marginLeft: "-24px"}} value={currency} onChange={e => changeCurrency(e.target.value)}>
                                            {currencies.map((currency) => (
                                            <option data-display={currency}>{currency}</option>
                                            ))}
                                            
                                        </select>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="cm_setting_block">
                            <div class="cm_setting_header">
                                <h2>Whitelisted Emails</h2>
                                <div className="button_wraper"><button className="cm_btn web_btn" data-bs-toggle="modal" data-bs-target="#exampleModal_10">Add Email</button></div>
                            </div>
                            <div class="cm_setting_body" style={{
                                maxHeight: "500px",
                                overflowY: "scroll"
                            }}>
                                <div class="cm_form_wrap cm_setting_drop">
                                    {/* <div class="select_dropdown cm_width">
                                        <label class="cm_setting_label">Theme</label>
                                        <select class="wide">
                                            <option data-display="Light">Light</option>
                                            <option value="1">Light Item 1</option>
                                            <option value="2">Light Item 2</option>
                                            <option value="3">Light Item 3</option>
                                        </select>
                                    </div> */}

{
                                            whitelistedemails.map((item, index) => {
                                                return (
                                                    <>
                                                    
                                            <tbody className="cm_tbody" >
                                                <tr className="cm_tr view" id={item.id}>
                                                    <td className="cm_td"><h4>{item.email}</h4></td>

                                                    
                                                    <td class="cm_td"><button onClick={()=> deleteWhitelistedEmail(item.email)} class="deleterow" type="submit"><svg width="20" height="20" viewBox="0 0 20 20" data-bs-toggle="modal" data-bs-target="#exampleModal4" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.5 5H4.16667H17.5" stroke="#EF5350" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M15.8332 5.00008V16.6667C15.8332 17.1088 15.6576 17.5327 15.345 17.8453C15.0325 18.1578 14.6085 18.3334 14.1665 18.3334H5.83317C5.39114 18.3334 4.96722 18.1578 4.65466 17.8453C4.3421 17.5327 4.1665 17.1088 4.1665 16.6667V5.00008M6.6665 5.00008V3.33341C6.6665 2.89139 6.8421 2.46746 7.15466 2.1549C7.46722 1.84234 7.89114 1.66675 8.33317 1.66675H11.6665C12.1085 1.66675 12.5325 1.84234 12.845 2.1549C13.1576 2.46746 13.3332 2.89139 13.3332 3.33341V5.00008" stroke="#EF5350" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/><path d="M8.3335 9.16675V14.1667" stroke="#EF5350" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/><path d="M11.6665 9.16675V14.1667" stroke="#EF5350" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/></svg></button></td>

                                                    
                                                    
                                                </tr>
                                                
                                                </tbody> 
                                                <br/>
                                                </>
                                                 )
                                                
                                            }
                                            )
                                        }
                                            
                                </div>
                            </div>
                        </div>

                        {/* <div class="cm_setting_block">
                            <div class="cm_setting_header">
                                <h2>Export</h2>
                            </div>
                            <div class="cm_setting_body">
                                <div class="cm_media">
                                    <div class="cm_setting_content paddi_and_mar">
                                        <a href="#0" class="cm_setting_btn">Export Purchases / Sales</a>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        {/* <div class="cm_setting_block">
                            <div class="cm_setting_header">
                                <h2>Auto-delist</h2>
                            </div>
                            <div class="cm_setting_body">
                                <div class="cm_media">
                                    <div class="cm_setting_content paddi_and_mar">
                                        <p class="cm_viagogo">If you are listed on viagogo and Stubhub and they sell on one, we will auto-delist on the other site for you.</p>
                                        <div class="form_group_wrap">
                                            <input type="checkbox" id="Would"/>
                                            <label for="Would">Would you like us to auto-delist your tickets?</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                   </div>

                </div>
          </div>
      </div>
      <EditSettings
      name={name}
      callback={editNameCallback}
      ></EditSettings>

      <AddEmail callback={whitelistCallback}/>

      <DeleteAllPurchases setInventory={setInventory}></DeleteAllPurchases>


<ToastContainer />


    </div>

    )

}