import $ from 'jquery';

$(document).ready(function (){
    // Sidebar Menu
    // $(".sidebar_menu ul li").click(function() {
    //     $(".sidebar_menu ul li").removeClass("active");
    //     $(this).addClass("active");
    // }); 

    //Menu Js
    $('.menu_trigger').click(function(){
        console.log(1)
        
        $(".main_content_lg").css("padding","")
        $(".sidebar_wrapper").css("left","")


    }); 
    $('.close_trigger, .cm-overlay').click(function(){

        console.log(2)
        
        $(".main_content_lg").css("padding", "0")
        $(".sidebar_wrapper").css("left","-300px")
    }); 

    
   

    //FAQ
    $('.faq_inner_block').slideUp();
    $('.faq_header').click(function () {
        $(this).parent().toggleClass('active').find('.faq_inner_block').slideToggle();
        $(this).parent().siblings().removeClass('active').find('.faq_inner_block').slideUp();
    });
});