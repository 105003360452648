 

import React from 'react';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useContext } from "react"
import { AppContext } from "../../context"

export const AddEvent =() =>{
    const [name, setName] = React.useState("");
    const [date , setDate] = React.useState(new Date());

    const {events, setEvents} = useContext(AppContext)
    const editSettings = ()=>{

        fetch(`${process.env.REACT_APP_BASE_URL}addEvent`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
                name: name,
                date: date
            })
        })


        setEvents([...events, {title: name, date: date, status: "Not Sold"}])
       

        toast.success("Event added", {
            position: toast.POSITION.TOP_RIGHT
        });


    }


    return (
        <>
        <div class="modal fade" id="eventModal" tabindex="-1" aria-labelledby="eventModal" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="modal_header_content">
                        <h5 class="modal-title" id="exampleModalLabel">Add Event</h5>
                    </div>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.00098 5L19.0005 18.9996" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M4.99947 18.9996L18.999 5" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="modal_body_details">
                        <form class="modal_form" >
                            <div class="cm_form_wrap">
                                <div class="cm_col_6">
                                    <div class="cm_date_box modal_cm_width">
                                        <label class="form">Event Name</label>
                                        <div class="input_wrap">
                                            <input type="text" value={name} onChange={e => setName(e.target.value)} placeholder='Taylor Swift Presale'/>
                                        </div>
                                    </div>

                                    
                                    
                                </div>

                                <div class="cm_col_6">
                                    <div class="cm_date_box modal_cm_width">
                                    <label>Date</label>
                                                <div class="input_wrap">
                                                    <DatePicker selected={date}
                                                        dateFormat="d MMMM yyyy"
                                                        popperPlacement="top-start"
                                                        onChange={date => setDate(date)}
                                                    />
                                                </div>
                                    </div>
                                </div>
                               
                            </div>
                        </form>
                    </div>
                    <div class="button_wraper"> 
                        <button class="cm_btn web_btn" data-bs-dismiss="modal" aria-label="Close" onClick={() => editSettings()}>Save</button>
                        <button class="cm_btn web_btn grey_color" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                    </div>
                </div>
            </div>
            </div>
        </div>
        </>
    )
}