import shapeLeft from '../images/login_shape_left.svg';
import shapeRight from '../images/login_right_shape.svg';
import hiIcon from '../images/hi_icon.svg';
import inputCheck from '../images/input_check.svg';
import showEye from '../images/show_eye.svg';
import hideEye from '../images/hide_eye.svg';
import logo from '../images/favicon.png';
import React from 'react';
import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha
  } from 'react-google-recaptcha-v3';

  import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const handlePasswordShow = () => {
    const password = document.querySelector(".cm_password");
    if (password.type === "password") {
        password.type = "text";
        if(document.getElementById("pw_eye").classList.contains("eye_hide")) {
            document.getElementById("pw_eye").classList.remove("eye_hide") 
            document.getElementById("pw_eye").classList.add("eye_show")}
    } else {
        password.type = "password";
        if(document.getElementById("pw_eye").classList.contains("eye_show")) {
            document.getElementById("pw_eye").classList.remove("eye_show") 
            document.getElementById("pw_eye").classList.add("eye_hide")}
    }
}

export const ChangePassword = () => {


    const [password, setPassword] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");

    const token = new URLSearchParams(window.location.search).get("token");

    


    const sendEmail = () => {

        if(password !== confirmPassword) {
            toast.error("Passwords do not match!")
            return
        }

        if(!token){
            toast.error("Invalid link!")
            return
        }
        const data = {
            token,
            password,
            
        }
        fetch(`${process.env.REACT_APP_BASE_URL}changePassword`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(res => res.json()).then(data => {
            if (data.success ) {
                toast.success("Password changed!")
                window.location.href = "/login"
            } else {
                toast.error("Error changing password! - Please try again")
            }
        })
    }

    return (
        <div class="main-wrapper">



        <div class="main_content">
            <div class="login_wrapper">
                <div class="login_left_shape">
                    <img src={shapeLeft} alt="shape"/>
                </div>
                <div class="login_right_shape">
                    <img src={shapeRight} alt="shape"/>
                </div>
                <div class="login_box">
                    <div class="login_common_title">
                    <img src={logo} alt="shape"/>
                        
                    </div>
                    <h2>Trackd</h2>
                    <div class="login_wlc_note">
                        <h3>Reset Password<img src={hiIcon} alt="icon"/></h3>
                        <p>Reset your password here</p>
                    </div>
                    <div class="login_form">
                        <form id="login_form" onSubmit={(e)=> e.preventDefault()}>
                        <div class="coomon_input_filed password_check">
                                <p>Password</p>
                                <input class="cm_password" type="password" placeholder="********" name="new-password" required autoComplete autocomplete="new-password" onChange={e=> setPassword(e.target.value)}/>
                                <div class="eye_hide_show eye_hide" onClick={() => handlePasswordShow()} id='pw_eye'>
                                    <img class="e_hide" src={hideEye} alt="eye"/>
                                    <img class="e_show" src={showEye} alt="eye"/>
                                </div>
                            </div>
                            <div class="coomon_input_filed password_check">
                                <p>Confirm Password</p>
                                <input class="cm_password" type="password" placeholder="********" name="new-password-confirm" required autocomplete="new-password" onChange={e=> setConfirmPassword(e.target.value)}/>
                                <div class="eye_hide_show eye_hide" onClick={() => handlePasswordShow()} id='pw_eye'>
                                    <img class="e_hide" src={hideEye} alt="eye"/>
                                    <img class="e_show" src={showEye} alt="eye"/>
                                </div>
                            </div>
                            
                            <div class="login_btn">
                                <button type="submit" onClick={()=> sendEmail()}>Reset</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="copyright">
                    <p>Copyright 2022 - TRACKD</p>
                    <ul>
                        <li><a href="/privacy">Privacy Policy</a></li>
                        <li><a href="/contact">Contact us</a></li>
                        <li><a href="/terms">Terms of use</a></li>
                    </ul>
                </div>
            </div>   
            <ToastContainer></ToastContainer>

        </div>
    </div>
    )
}