

import { toast } from 'react-toastify';

export const DeleteExpense =({id, expenses,setExpenses,callback}) =>{

    const deleteRow = ()=>{

        fetch(`${process.env.REACT_APP_BASE_URL}deleteExpense`,{
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
                id: id
            })
        })

        let newInventory = expenses.filter(item => item.id !== id)
        setExpenses(newInventory)

        toast.success("Expense Deleted", {
            position: toast.POSITION.TOP_RIGHT
        });


    }

    return (
        <>
        <div class="modal fade" id="exampleModal4" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="modal_header_content">
                        <h5 class="modal-title" id="exampleModalLabel">Are you sure you want to delete this expense?</h5>
                    </div>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.00098 5L19.0005 18.9996" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M4.99947 18.9996L18.999 5" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="button_wraper"> 
                        <button class="cm_btn web_btn" style={{marginLeft:"35%"}} onClick={()=>deleteRow()} data-bs-dismiss="modal" aria-label="Close">Confirm</button>
                        <button class="cm_btn web_btn grey_color" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                    </div>
                </div>
            </div>
            </div>
        </div>
        </>
    )
}