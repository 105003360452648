import shapeLeft from "../images/login_shape_left.svg";
import shapeRight from "../images/login_right_shape.svg";
import hiIcon from "../images/hi_icon.svg";
import inputCheck from "../images/input_check.svg";
import showEye from "../images/show_eye.svg";
import hideEye from "../images/hide_eye.svg";
import logo from "../images/favicon.png";
import React from "react";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const Login = () => {
  const [token, setToken] = React.useState("");

  const handlePasswordShow = () => {
    const password = document.querySelector(".cm_password");
    if (password.type === "password") {
      password.type = "text";
      if (document.getElementById("pw_eye").classList.contains("eye_hide")) {
        document.getElementById("pw_eye").classList.remove("eye_hide");
        document.getElementById("pw_eye").classList.add("eye_show");
      }
    } else {
      password.type = "password";
      if (document.getElementById("pw_eye").classList.contains("eye_show")) {
        document.getElementById("pw_eye").classList.remove("eye_show");
        document.getElementById("pw_eye").classList.add("eye_hide");
      }
    }
  };

  const handleTokenChange = (toke) => {
    if (token === "") {
      setToken(toke);
    } else {
      return;
    }
  };

  const login = () => {
    const email = document.getElementById("login_form").elements["email"].value;
    const password =
      document.getElementById("login_form").elements["password"].value;
    const remember = document.getElementById("rem").checked;

    //check if any field is empty
    if (email === "" || password === "") {
      toast.error("Please fill all fields");
      return;
    }

    const data = {
      email: email,
      password: password,
      remember: remember,
      code: token,
    };
    fetch(`${process.env.REACT_APP_BASE_URL}login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          toast.success("Login successful! - Redirecting to dashboard...");
          document.cookie =
            "token=" + data.token + " ; domain=.trackd.gg; path=/";
          window.location.href = "/app/dashboard";
        } else {
          toast.error("Login failed! - Please check your credentials");
        }
      });
  };

  return (
    <div class="main-wrapper">
      <GoogleReCaptchaProvider reCaptchaKey="6LeOhsMkAAAAANhmPWpO--OSybTzYy_otYWT3PKY">
        <GoogleReCaptcha
          onVerify={(token) => {
            handleTokenChange(token);
          }}
        />
      </GoogleReCaptchaProvider>

      <div class="main_content">
        <div class="login_wrapper">
          <div class="login_left_shape">
            <img src={shapeLeft} alt="shape" />
          </div>
          <div class="login_right_shape">
            <img src={shapeRight} alt="shape" />
          </div>
          <div class="login_box">
            <div class="login_common_title">
              <img src={logo} alt="shape" />
            </div>
            <h2>Trackd</h2>
            <div class="login_wlc_note">
              <h3>
                Welcome <img src={hiIcon} alt="icon" />
              </h3>
              <p>Please sign into your account below</p>
            </div>
            <div class="login_form">
              <form id="login_form" onSubmit={(e) => e.preventDefault()}>
                <div class="coomon_input_filed email_check">
                  <p>Email Address</p>
                  <input
                    type="email"
                    placeholder="sarah_adams@gmail.com"
                    name="email"
                    required
                  />
                  <img class="check_icon" src={inputCheck} alt="check" />
                </div>
                <div class="coomon_input_filed password_check">
                  <p>Password</p>
                  <input
                    class="cm_password"
                    type="password"
                    placeholder="********"
                    name="password"
                    required
                  />
                  <div
                    class="eye_hide_show eye_hide"
                    onClick={() => handlePasswordShow()}
                    id="pw_eye"
                  >
                    <img class="e_hide" src={hideEye} alt="eye" />
                    <img class="e_show" src={showEye} alt="eye" />
                  </div>
                </div>
                <div class="remember_blcok">
                  <div class="coomn_checkbox">
                    <input type="checkbox" id="rem" />
                    <label for="rem">Remember me</label>
                  </div>
                  <a href="forgotpassword">Forgot password?</a>
                </div>
                <div class="login_btn">
                  <button type="submit" onClick={() => login()}>
                    Login
                  </button>
                  <p>
                    Don’t have an account?{" "}
                    <a href="https://whop.com/checkout/plan_rZQM5DgxXI3lX/">
                      Create an Account
                    </a>
                  </p>
                  {/* <p>Forgot Password? <a href="forgotpassword">Reset your password</a></p> */}
                </div>
                <div class="login_btn">
                  <p>Other sign in options: </p>
                  <br></br>
                  <button
                    type="submit"
                    className="whop_button"
                    onClick={() =>
                      (window.location.href =
                        "https://whop.com/oauth/?redirect_uri=https://api.trackd.gg/whop/callback&client_id=Vtg5J97gAhktboNpUnJAyeYrhptD5s1pKk-LuirYBZQ")
                    }
                  >
                    Sign in with Whop
                  </button>

                  <button
                    type="submit"
                    className="discord_button"
                    onClick={() =>
                      (window.location.href =
                        "https://discord.com/oauth2/authorize?client_id=1073244611633483856&response_type=code&redirect_uri=https%3A%2F%2Ftrackd.fnf-toolkit.com%2Fdiscord&scope=guilds+email+identify")
                    }
                  >
                    Sign in with Discord
                  </button>
                  {/* <p>Forgot Password? <a href="forgotpassword">Reset your password</a></p> */}
                </div>
              </form>
            </div>
          </div>
          <div class="copyright">
            <p>Copyright 2024 - TRACKD</p>
            <ul>
              <li>
                <a href="/privacy">Privacy Policy</a>
              </li>
              <li>
                <a href="/contact">Contact us</a>
              </li>
              <li>
                <a href="/terms">Terms of use</a>
              </li>
            </ul>
          </div>
        </div>
        <ToastContainer></ToastContainer>
      </div>
    </div>
  );
};
