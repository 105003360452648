
import shapeLeft from '../images/login_shape_left.svg';
import shapeRight from '../images/login_right_shape.svg';


export const NotFound = () => {

    return (
        <div class="main-wrapper">

        <div class="main_content">
            <div class="login_wrapper">
                <div class="login_left_shape">
                    <img src={shapeLeft} alt="shape"/>
                </div>
                <div class="login_right_shape">
                    <img src={shapeRight} alt="shape"/>
                </div>
                <div class="login_box">
                    <div class="login_common_title">
                        <h2>404 NOT FOUND</h2>
                    </div>
                    <div class="login_wlc_note">
                        <p>The page you are looking for doesn't exist. Please try again or <a href="/">go home</a></p>
                    </div>
                </div>
                <div class="copyright">
                    <p>Copyright 2022 - TRACKD</p>
                    <ul>
                        <li><a href="/privacy">Privacy Policy</a></li>
                        <li><a href="/contact">Contact us</a></li>
                        <li><a href="/terms">Terms of use</a></li>
                    </ul>
                </div>
            </div>   
        </div>
    </div>
    )

}