import shapeLeft from "../images/login_shape_left.svg";
import shapeRight from "../images/login_right_shape.svg";
import hiIcon from "../images/hi_icon.svg";
import inputCheck from "../images/input_check.svg";
import showEye from "../images/show_eye.svg";
import hideEye from "../images/hide_eye.svg";
import logo from "../images/favicon.png";
import React from "react";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const Callback = () => {
  //get query params
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get("token");

  console.log(token);

  //check if token is present
  if (token === null) {
    window.location.href = "/";
  }

  //set token as cookie
  document.cookie = `token=${token}; path=/`;

  //redirect to dashboard
  setTimeout(function () {
    window.location.href = "/app/dashboard";
  }, 3000);

  return (
    <div class="main-wrapper">
      <div class="main_content">
        <div class="login_wrapper">
          <div class="login_left_shape">
            <img src={shapeLeft} alt="shape" />
          </div>
          <div class="login_right_shape">
            <img src={shapeRight} alt="shape" />
          </div>
          <div class="login_box">
            <div class="login_common_title">
              <img src={logo} alt="shape" />
            </div>
            <h2>Trackd</h2>
            <div class="login_wlc_note">
              <h3>
                Welcome <img src={hiIcon} alt="icon" />
              </h3>
              <p>Login successful, redirecting to dashboard...</p>
            </div>
          </div>
          <div class="copyright">
            <p>Copyright 2024 - TRACKD</p>
            <ul>
              <li>
                <a href="/privacy">Privacy Policy</a>
              </li>
              <li>
                <a href="/contact">Contact us</a>
              </li>
              <li>
                <a href="/terms">Terms of use</a>
              </li>
            </ul>
          </div>
        </div>
        <ToastContainer></ToastContainer>
      </div>
    </div>
  );
};
