import DatePicker from "react-datepicker";
import React from "react";

import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

import "react-datepicker/dist/react-datepicker.css";

import { useLocalStorage } from "usehooks-ts"
import { currencies } from "../../constants/constants";
import { convertEuropeanNumber } from "../Util/EUnumberFormatter";


export const AddExpense = ({expense, setExpenses,callback}) =>{

    const [name, setName] = React.useState("");
    const [renewable, setRenewable] = React.useState(false);
    const [date, setDate] = React.useState(new Date());
    const [amount, setAmount] = React.useState("");
    const [currency,setCurrency] =React.useState("GBP")



    const handleSubmit = (e) => {
        e.preventDefault();
        const expenses = {
            name,
            renewable,
            date,
            amount,
            currency
        }

        expenses.amount = convertEuropeanNumber(expenses.amount)


        let wrongFields = []
        //validate every field
        for (const i in expenses) {
            if (expenses[i] === '') {
                wrongFields.push(i)
                
                continue
            }
        }

        if (wrongFields.length > 0) {
            toast.error("Please fill all the fields");
            return
        }

        fetch(`${process.env.REACT_APP_BASE_URL}addExpense`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(expenses),
            credentials: "include",
        })
        .then((res) => res.json())
        .then((data) => {
            
            expenses.date = new Date(expenses.date).toString()
                toast.success("Expense added successfully");
                setExpenses([...expense, expenses])
            
        })
        
        .catch((err) => {
            toast.error("Something went wrong");
        })
    }



    return (

        <>
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="modal_header_content">
                        <h5 class="modal-title" id="exampleModalLabel">Manually add an expense</h5>
                        <p>These may include any third party tools you use (E.G. Cook groups, bots, proxies)</p>
                    </div>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.00098 5L19.0005 18.9996" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M4.99947 18.9996L18.999 5" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="modal_body_details">
                        <form class="modal_form">
                            <div class="cm_form_wrap">
                                <div class="cm_col_6">
                                    <div class="cm_date_box modal_cm_width">
                                        <label class="form">Expense Name</label>
                                        <div class="input_wrap">
                                            <input type="text" placeholder="Ricky Gervais" onChange={e => setName(e.target.value)}/>
                                        </div>
                                    </div>
                                   
                                    <div class="cm_date_box modal_cm_width">
                                        <label>Date</label>
                                        <div class="input_wrap">
                                        <DatePicker selected={date}
                                        dateFormat="dd/MM/yyyy"
                                         popperPlacement="top-start"
                                         onChange={date => setDate(date)}
                                            />
                                        </div>
                                    </div>
                                    
                                </div>
                                
    
                                <div class="cm_col_6">
                                   
                                    <div class="cm_date_box modal_cm_width">
                                        <label>Amount</label>
                                        <div class="input_wrap">
                                            <input type="text" placeholder="£99.99 (intl format)" onChange={e => setAmount(e.target.value)}/>
                                        </div>
                                    </div>

                                    <div class="cm_date_box modal_cm_width">
                                        <label>Currency</label>
                                        <div class="input_wrap">
                                        <select class="nice-select wide" style={{paddingLeft: "0px",
                                                                                width:"106%",
                                                                                marginLeft: "-24px"}} value={currency} onChange={e => setCurrency(e.target.value)}>
                                            {currencies.map((currency) => (
                                            <option data-display={currency}>{currency}</option>
                                            ))}
                                            
                                        </select>
                                    </div>
                                    </div>
                                    
                                    
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="button_wraper"> 
                        <button class="cm_btn web_btn" data-bs-dismiss="modal" aria-label="Close" onClick={e => handleSubmit(e)}>Save</button>
                        <button class="cm_btn web_btn grey_color" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                    </div>
                </div>
            </div>
            <ToastContainer />
            </div>
        </div>
        </>
    )
}