

import { toast } from 'react-toastify';
import { useState } from 'react';

export const DeleteAllPurchases =({setInventory}) =>{

    const [initiated, setInitiated] = useState(false)
    const [code , setCode] = useState("")

    const initiateDeleteAll = ()=>{

        fetch(`${process.env.REACT_APP_BASE_URL}initiateDeleteAllItems`,{
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        })

        setInitiated(true)
        return

    }

    const deleteAll = ()=>{

        fetch(`${process.env.REACT_APP_BASE_URL}confirmDeleteAllItems`,{
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
                code
            })
        })

        setInventory([])
        setInitiated(false)

        toast.success("Purchases Deleted", {
            position: toast.POSITION.TOP_RIGHT
        });

       

    }


    const getHtml = ()=>{

        return (
            
                initiated ?  <>
                <div class="modal fade" id="exampleModal7" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div class="modal_header_content">
                                <h5 class="modal-title" id="exampleModalLabel">Please check your email for your confirmation code (Check your spam too!)</h5>
                                <h4 class="modal-title" id="exampleModalLabel">This cannot be undone once confirmed</h4>
                            </div>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.00098 5L19.0005 18.9996" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M4.99947 18.9996L18.999 5" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            </button>
                        </div>
                        <div style={{padding:"10px"}}>
                        <div class="input_wrap" style={{width:"50%", marginLeft:"25%", height: "50px"}}>
                                    <input type="text" value={code} onChange={e => setCode(e.target.value)} />
                        </div>
                        </div>
                        <div class="modal-body">
                            <div class="button_wraper"> 
                                <button class="cm_btn web_btn" style={{marginLeft:"35%"}} onClick={()=>deleteAll()} data-bs-dismiss="modal" aria-label="Close">Confirm</button>
                                <button class="cm_btn web_btn grey_color" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                </> :  <>
                <div class="modal fade" id="exampleModal7" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div class="modal_header_content">
                                <h5 class="modal-title" id="exampleModalLabel">Are you sure you want to delete all items?</h5>
                            </div>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.00098 5L19.0005 18.9996" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M4.99947 18.9996L18.999 5" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="button_wraper"> 
                                <button class="cm_btn web_btn" style={{marginLeft:"35%"}} onClick={()=>initiateDeleteAll()}>Confirm</button>
                                <button class="cm_btn web_btn grey_color" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                </>
              
 
        )
    }

    return (
        getHtml()
    )
}