import React from "react"
import $ from "jquery"
import { TailSpin } from 'react-loading-icons'
import { useLocalStorage } from "usehooks-ts"
import '../js/custom.js'
import { useContext } from "react"
import { AppContext } from "../../context"
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { formatGraphDate } from "../Util/date.js"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";




export const Dashboard = () =>{


    const {dashboard,setDashboard, graphData, setGraphData} = useContext(AppContext)


    
    const [loading, setLoading] = React.useState(false)
    const [showExpenses, setShowExpenses] = React.useState(true)
    const [showUnsold, setShowUnsold] = React.useState(true)
    const [currency, setCurrency] = useLocalStorage("currency", "GBP")
    const [graphToShow, setGraphToShow] = React.useState("Total Profit")
    const [dateFiler, setDateFilter] = React.useState("Month")
    const [dataSplit, setDataSplit] = React.useState("Cumulative")


    const formatter = new Intl.NumberFormat("en-GB",{
        style: "currency",
        currency: currency,
        minimumFractionDigits: 2
    })

    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const onChange = (dates) => {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    };

    const [purchaseStartDate, setPurchaseStartDate] = React.useState(null);
    const [purchaseEndDate, setPurchaseEndDate] = React.useState(null);
    const onPurchaseChange = (dates) => {
      const [start, end] = dates;
      setPurchaseStartDate(start);
      setPurchaseEndDate(end);
    };



    const getJsonDataKey = ()=>{
        switch (dataSplit){
            case "Cumulative":
                return "cumulative"
            case "Day":
                return "individual"
            default:
                return "cumulative"
        }
    }


    const getDataKey = ()=>{
        switch (graphToShow) {
            case "Total Tickets Bought":
                return "tickets"
            case "Guaranteed Incoming":
                return "incoming"
            case "Total Profit":
                return "profit"
            case "Total Spent":
                return "spent"
            case "Total Received":
                return "received"

            
            default:
                break;

    }
}

    const getDateFilter = (data) => {
    let cumulativeTotal = 0;
    const result = [];
    const key = getDataKey()

    if(!data){
        return []
    }


    if(dataSplit === "Cumulative"){

    for (const entry of data) {
        const date = new Date(entry.name);

        let monthYear = date.toISOString().substring(0, 7); // Format: "YYYY-MM"

        switch (dateFiler) {
            case "Day":
                return data.map(item => {
                    item.name = formatGraphDate(new Date(item.name), dateFiler)
                    return item
                })
            case "Month":
                monthYear = date.toISOString().substring(0, 7); // Format: "YYYY-MM"
                break;
            case "Year":
                monthYear = date.toISOString().substring(0, 4); // Format: "YYYY"
                break;
            default:
                return data
        }

         cumulativeTotal = entry[key];
        

        let index = result.findIndex((x) => x.name === monthYear);

        if (index !== -1) {
            result[index][key] = cumulativeTotal;
            continue;
        }

       let newItem = {
        name: monthYear,
       }

         newItem[key] = cumulativeTotal

        result.push(newItem);
    }

     result.map((item,index) => {
        item.name = formatGraphDate(new Date(item.name), dateFiler)
    })


    return result;
}else {
    for (const entry of data) {
        const date = new Date(entry.name);


        let monthYear = date.toISOString().substring(0, 7); // Format: "YYYY-MM"

        switch (dateFiler) {
            case "Day":
                return data.map(item => {
                    item.name = formatGraphDate(new Date(item.name), dateFiler)
                    return item
                })

            case "Month":
                monthYear = date.toISOString().substring(0, 7); // Format: "YYYY-MM"
                break;
            case "Year":
                monthYear = date.toISOString().substring(0, 4); // Format: "YYYY"
                break;
            default:
                return data
        }

        

        let index = result.findIndex((x) => x.name === monthYear);

        if (index !== -1) {
            result[index][key] = entry[key] + result[index][key];
            continue;
        }

       let newItem = {
        name: monthYear,
       }

         newItem[key] = entry[key]

        result.push(newItem);
    }


    result.map((item,index) => {
        item.name = formatGraphDate(new Date(item.name), dateFiler)
    })


    return result;
}
}


    const getGraphData = (

    )=>{


        

        if(JSON.stringify(graphData) === "{}"){
            return []
        }

        const key = getJsonDataKey()

        switch (graphToShow) {
            case "Total Tickets Bought":
                return getDateFilter(graphData.totalTicketsPerDay[key])
            case "Guaranteed Incoming":
                return getDateFilter(graphData.totalGuaranteedIncomingPerDay[key])
            case "Total Profit":
                return getDateFilter(graphData.totalRealisedProfitPerDay[key])
            case "Total Spent":
                return getDateFilter(graphData.totalSpentPerDay[key])
            case "Total Received":
                return getDateFilter(graphData.totalRecievedPerDay[key])
            
            default:
                break;
        }
    }


    React.useEffect(() => {

        // setCurrency("EUR")
    // $('.faq_inner_block').slideUp();
    // $('.faq_header').on("click",function () {
    //     $(this).parent().toggleClass('active').find('.faq_inner_block').slideToggle();
    //     $(this).parent().siblings().removeClass('active').find('.faq_inner_block').slideUp();
    // });


    if(dashboard.length ===0){

    setLoading(true)

    while (true){

        try{

    fetch(`${process.env.REACT_APP_BASE_URL}getDashboard?currency=${currency}`, {
        method: "GET",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        
    }).then(res => res.json()).then(data => {

        if (data.success) {

            setDashboard(data.dashboard)
            setLoading(false)
        } else {
                window.location.href = "/login";
        }


    })
    break;
}catch{
    continue
}
}

    }
}, [])


    React.useEffect(() => {


        if(graphData.length === undefined){
        fetch(`${process.env.REACT_APP_BASE_URL}graphdata?currency=${currency}`, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        }).then(res => res.json()).then(data => {
            setGraphData(data)
        })
    }
    },[])




    const getProfit = () => {

        if(showExpenses && showUnsold){
            return dashboard.totalProfit
        }else if(showExpenses && !showUnsold){
            return dashboard.totalSoldProfit 
        }else if(!showExpenses && showUnsold){
            return dashboard.totalProfit + (dashboard.expenses)
        }else if(!showExpenses && !showUnsold){
            return dashboard.totalProfit + (dashboard.unsoldValue) + (dashboard.expenses)
        }

    }

    const getRealisedProfit = () => {

        if(showExpenses && showUnsold){
            return dashboard.realisedProfit
        }else if(showExpenses && !showUnsold){
            return dashboard.realisedProfit + (dashboard.unsoldValue)
        }else if(!showExpenses && showUnsold){
            return dashboard.realisedProfit + (dashboard.expenses)
        }else if(!showExpenses && !showUnsold){
            return dashboard.realisedProfit + (dashboard.unsoldValue) + (dashboard.expenses)
        }

    }


    const getTotalSpend = () => {
        if(showExpenses && showUnsold){
            return dashboard.totalSpent
        }else if (showExpenses && !showUnsold){
            return dashboard.totalSpent - (dashboard.unsoldValue)
        }else if (!showExpenses && showUnsold){
            return dashboard.totalSpent - (dashboard.expenses)
        }else if (!showExpenses && !showUnsold){
            return dashboard.totalSpent - (dashboard.unsoldValue) - (dashboard.expenses)
        }
        
    }

    const getTickets = () => {
        if(showUnsold){
            return dashboard.inventoryNumber
        }else {
            return 0
        }
    }
    


    const CustomTooltip = ({ active, payload, label }) => {

        if(JSON.stringify(payload) !== "{}"){
    if (active && payload && payload.length) {
        return (
        <div className="custom-tooltip">
            <p className="label">{`${label} : ${ graphToShow === "Total Tickets Bought"? payload[0].value: formatter.format(payload[0].value)}`}</p>
        </div>
        );
    }
}

    return null;
    };

    return (
        <body class="templete dashboard-v1">
        <div className="main_content">

        <div className="main_content_lg">
            <div className="common_content_pad_block">
                <div className="header_wrapper">
                    <div className="header_wrap">
                        <div className="menu_trigger">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16.842" height="14.677" viewBox="0 0 16.842 14.677">
                                <g id="Close_Menu" data-name="Close Menu" transform="translate(0.5 0.707)">
                                <path id="Shape" d="M-5.526.5H0" transform="translate(15.842 12.503)" fill="none" stroke="#535776" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/>
                                <path id="Shape-2" data-name="Shape" d="M0,0-6.263,6.632,0,13.263" transform="translate(6.263)" fill="none" stroke="#535776" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/>
                                <path id="Shape-3" data-name="Shape" d="M-15.842,0H0" transform="translate(15.842 6.563)" fill="none" stroke="#535776" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/>
                                <path id="Shape-4" data-name="Shape" d="M-5.526.5H0" transform="translate(15.842 -0.368)" fill="none" stroke="#535776" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/>
                                </g>
                            </svg>
                        </div>
                        <div className="common_heading">
                            <h1 className="header_title">Dashboard</h1>
                            <p className="header_content">Summary of all purchases and sales</p>
                        </div>
                    </div>
                </div>

                

                <div className="inner_content_wrapper dashboard_one_content">
                    <div className="row cm_row">
                        <div className="col-xxl-8 col-xl-7 col_lg_8 col-12">
                            <div className="cm_form_wrap">

                                
                                

                            <div className="button_wraper"> <button className="cm_btn web_btn" onClick={()=>setShowExpenses(e => !e)}>Toggle Expenses: {showExpenses ? "Included": "Not Included"}</button></div>
                            <div className="button_wraper"> <button className="cm_btn web_btn" onClick={()=>setShowUnsold(e => !e)}>Toggle Unsold Tickets: {showUnsold ? "Included": "Not Included"}</button></div>

                                {/* <div className="cm_date_box cm_width">
                                    <label className="input_wrap">
                                        <input type="number" placeholder="Purchase Date Range"/>
                                        <span className="calendar_icon">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 2V5" stroke="#6C6C6C" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M16 2V5" stroke="#6C6C6C" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M3.5 9.09009H20.5" stroke="#6C6C6C" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z" stroke="#6C6C6C" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M15.6947 13.7H15.7037" stroke="#6C6C6C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M15.6947 16.7H15.7037" stroke="#6C6C6C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M11.9955 13.7H12.0045" stroke="#6C6C6C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M11.9955 16.7H12.0045" stroke="#6C6C6C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M8.29431 13.7H8.30329" stroke="#6C6C6C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M8.29431 16.7H8.30329" stroke="#6C6C6C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </span>
                                    </label>
                                </div> */}

                                
                            </div>

                            <div className="event_tota_price">
                                <div className="row">
                                    <div className="col-xxl-3 col-sm-6 col-6">
                                        <div className="event_tota_box green_box">
                                            <div className="event_tota_content">
                                                <h2 className="event_tota_title">Total Profit</h2>
                                                <h3 className="total_text_wrap"> {loading? <TailSpin stroke="#000000"/> : formatter.format(Number(getProfit()).toFixed(2))}</h3>
                                                <p className="total_text_details">(Incl. Accured profit)</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-xxl-3 col-sm-6 col-6">
                                        <div className="event_tota_box pink_box">
                                            <div className="event_tota_content">
                                                <h2 className="event_tota_title">Accrued Profit</h2>
                                                <h3 className="total_text_wrap">£ 5,000</h3>
                                                <p className="total_text_details">(Sales Pending Payment)</p>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-xxl-3 col-sm-6 col-6">
                                        <div className="event_tota_box blue_box">
                                            <div className="event_tota_content">
                                                <h2 className="event_tota_title">Realised Profit</h2>
                                                <h3 className="total_text_wrap">{loading? <TailSpin stroke="#000000"/> : formatter.format(Number(getRealisedProfit()).toFixed(2))}</h3>
                                                <p className="total_text_details">(Total out - Total in, Not Incl. Accured Profit)</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xxl-3 col-sm-6 col-6">
                                        <div className="event_tota_box red_box">
                                            <div className="event_tota_content">
                                                <h2 className="event_tota_title">Guaranteed Incoming</h2>
                                                <h3 className="total_text_wrap">{loading? <TailSpin stroke="#000000"/> :formatter.format(Number(dashboard.guaranteedIncoming).toFixed(2))}</h3>
                                                <p className="total_text_details">(Proceeds from sales pending payout)</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xxl-3 col-sm-6 col-6">
                                        <div className="event_tota_box azure_box">
                                            <div className="event_tota_content">
                                                <h2 className="event_tota_title">Total Spent</h2>
                                                <h3 className="total_text_wrap">{loading? <TailSpin stroke="#000000"/> : formatter.format(Number(getTotalSpend()).toFixed(2))}</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xxl-3 col-sm-6 col-6">
                                        <div className="event_tota_box red_box">
                                            <div className="event_tota_content">
                                                <h2 className="event_tota_title">Total Sold</h2>
                                                <h3 className="total_text_wrap">{loading? <TailSpin stroke="#000000"/> : formatter.format(Number(dashboard.totalSoldNumber).toFixed(2))}</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xxl-3 col-sm-6 col-6">
                                        <div className="event_tota_box yellow_box">
                                            <div className="event_tota_content">
                                                <h2 className="event_tota_title">Tickets Sold</h2>
                                                <h3 className="total_text_wrap">{loading? <TailSpin stroke="#000000"/> : dashboard.ticketsSold}</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xxl-3 col-sm-6 col-6">
                                        <div className="event_tota_box pink_box">
                                            <div className="event_tota_content">
                                                <h2 className="event_tota_title">Tickets in inventory</h2>
                                                <h3 className="total_text_wrap">{loading? <TailSpin stroke="#000000"/> :getTickets()}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            

                            <div className="chart_wrap">
                                <div className="card">
                                    <div className="card-body chart_body">
                                      <div className="chart_heading d-flex flex-column flex-lg-row">
                                        <div className="chart_title">
                                        <select class="nice-select wide"  value={graphToShow} onChange={e => setGraphToShow(e.target.value)}>

                                            <option value="Total Tickets Bought">Total Tickets Bought</option>
                                            <option value="Guaranteed Incoming">Guaranteed Incoming</option>
                                            <option value="Total Spent">Total Spent</option>
                                            <option value="Total Profit">Total Profit</option>
                                            <option value="Total Received">Total Received</option>
                                            
                                           
                                            
                                        </select>
                                  
                                        </div>
                                        <div className="chart_title">
                                        
                                    <select class="nice-select wide"  value={dateFiler} onChange={e => setDateFilter(e.target.value)}>

                                            <option value="Day">Day</option>
                                            <option value="Month">Month</option>
                                            <option value="Year">Year</option>
                                            
                                           
                                            
                                        </select>
                                  
                                        </div>
                                        <div className="chart_title">
                                        <select class="nice-select wide"  value={dataSplit} onChange={e => setDataSplit(e.target.value)} >

                                            <option value="Cumulative" defaultValue={"Cumulative"}>Cumulative</option>
                                            <option value="Day">Individual</option>
                                            
                                           
                                            
                                        </select>
                                  
                                        </div>
                                        {/* <div className="chart_button">
                                            <ul className="nav nav-tabs sales-mini-tabs ms-lg-auto mb-4 mb-md-0" role="tablist">
                                                <li className="nav-item">
                                                    <a className="nav-link" id="sales-statistics_switch_1" data-bs-toggle="tab" role="tab" aria-selected="false">Day</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" id="sales-statistics_switch_2" data-bs-toggle="tab" role="tab" aria-selected="false">Week</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link active" id="sales-statistics_switch_3" data-bs-toggle="tab" role="tab" aria-selected="true">Month</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" id="sales-statistics_switch_4" data-bs-toggle="tab" role="tab" aria-selected="false">Year</a>
                                                </li>
                                            </ul>
                                        </div> */}
                                         
                                      </div>
                                      <div className="d-flex flex-column flex-lg-row">
                                        <div className="ms-lg-auto" id="sales-statistics-legend"></div>
                                      </div>
                                        
                                    </div>
                                    <ResponsiveContainer width="100%" height={200}>
                                           
                                                <LineChart
                                                    width={500}
                                                    height={200}
                                                    data={getGraphData()}
                                                    margin={{
                                                    top: 10,
                                                    right: 30,
                                                    left: 0,
                                                    bottom: 0,
                                                    }}
                                                    
                                                
                                                >
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis dataKey="name" />
                                                    <YAxis dataKey={getDataKey()}/>
                                                    <Tooltip content={JSON.stringify(graphData) === "{}" ? null: <CustomTooltip/>}/>
                                                    <Line type="monotone" dataKey={getDataKey()} stroke="#8884d8" fill="#8884d8" />
                                                </LineChart>


                                                </ResponsiveContainer>


                                        
                                  </div>
                            </div>
                            {/* <div style={{
                                    display: "flex",
                             justifyContent: "center"
                            }}  >
                            <div class="cm_date_box cm_width">
                                <label class="input_wrap">
                                <DatePicker 
                                         popperPlacement="left"
                                         selected={startDate}
                                        onChange={onChange}
                                        dateFormat="dd/MM/yyyy"
                                        startDate={startDate}
                                        endDate={endDate}
                                        selectsRange
                                        placeholderText="Event Date Range"
                                         
                                            />
                                    <span class="calendar_icon">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 2V5" stroke="#6C6C6C" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M16 2V5" stroke="#6C6C6C" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M3.5 9.09009H20.5" stroke="#6C6C6C" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z" stroke="#6C6C6C" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M15.6947 13.7H15.7037" stroke="#6C6C6C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M15.6947 16.7H15.7037" stroke="#6C6C6C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M11.9955 13.7H12.0045" stroke="#6C6C6C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M11.9955 16.7H12.0045" stroke="#6C6C6C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M8.29431 13.7H8.30329" stroke="#6C6C6C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M8.29431 16.7H8.30329" stroke="#6C6C6C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </span>
                                </label>
                            </div>
                            <div class="cm_date_box cm_width">
                                <label class="input_wrap">
                                <DatePicker 
                                         popperPlacement="right"
                                         selected={purchaseStartDate}
                                         dateFormat="dd/MM/yyyy"
                                        onChange={onPurchaseChange}
                                        startDate={purchaseStartDate}
                                        endDate={purchaseEndDate}
                                        selectsRange
                                        placeholderText="Purchase Date Range"
                                       popperProps={{
                                    positionFixed: true // use this to make the popper position: fixed
                                }}
                                                                            
                                            />
                                    <span class="calendar_icon">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 2V5" stroke="#6C6C6C" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M16 2V5" stroke="#6C6C6C" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M3.5 9.09009H20.5" stroke="#6C6C6C" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z" stroke="#6C6C6C" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M15.6947 13.7H15.7037" stroke="#6C6C6C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M15.6947 16.7H15.7037" stroke="#6C6C6C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M11.9955 13.7H12.0045" stroke="#6C6C6C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M11.9955 16.7H12.0045" stroke="#6C6C6C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M8.29431 13.7H8.30329" stroke="#6C6C6C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M8.29431 16.7H8.30329" stroke="#6C6C6C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </span>
                                </label>
                            </div>
                            </div> */}
                        </div>

                        

                        <br></br>
                        <div className="col-xxl-4 col-xl-5 col_lg_4 col-12">
                            <div className="inventory_wrap">
                                <div className="inventory_title_wrap">
                                    <h2>Inventory</h2>
                                    <p>Tickets yet to be sold</p>
                                </div>

                                <div className="unrealised_profit">
                                    <p>Est. Value of Inventory</p>
                                    <h2>{loading? <TailSpin
                                    stroke="#000000"/> : formatter.format(dashboard.potentialProfit)}</h2>
                                    <span>(Est. Value of unsold tickets)</span>
                                </div>

                                <div className="eut_wrap">
                                    <div className="eut_title_wrap">
                                        <h6 className="eut_title_item">Event</h6>
                                        <h6 className="eut_title_item">Unrealised Profit</h6>
                                        <h6 className="eut_title_item"># Tickets</h6>
                                    </div>

                                    <div className="eut_details_wrap">

                                        {dashboard.inventoryProfit ? dashboard.inventoryProfit.map(item => {

                                            return (
                                            <div className="faq_outer">
                                                <div className="faq_block" id="1">
                                                    <div className="faq_header">
                                                        <div className="faq_title">
                                                            <h4>{item.name}</h4><h4>{formatter.format(Number(item.estimatedProfit).toFixed(2))}</h4><h4>{(item.quantity) } <span className="faq_image">
                                                                
                                                            </span></h4>
                                                        </div>
                                                    </div>
                                        
                                                </div>
                                        

                                        </div>
                                            )

                                            }): null}
                                        
                                    </div>

                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
          </div>
      </div>

    </div>
    </body>
   
    )
}