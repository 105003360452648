

export const Activities = () => {

    return (
        <body class="templete activities_page">
        <div class="main_content">

            <div class="main_content_lg">
                <div class="common_content_pad_block">
                    <div class="header_wrapper">
                        <div class="header_wrap">
                            <div class="menu_trigger ">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16.842" height="14.677" viewBox="0 0 16.842 14.677">
                                    <g id="Close_Menu" data-name="Close Menu" transform="translate(0.5 0.707)">
                                    <path id="Shape" d="M-5.526.5H0" transform="translate(15.842 12.503)" fill="none" stroke="#535776" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
                                    <path id="Shape-2" data-name="Shape" d="M0,0-6.263,6.632,0,13.263" transform="translate(6.263)" fill="none" stroke="#535776" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
                                    <path id="Shape-3" data-name="Shape" d="M-15.842,0H0" transform="translate(15.842 6.563)" fill="none" stroke="#535776" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
                                    <path id="Shape-4" data-name="Shape" d="M-5.526.5H0" transform="translate(15.842 -0.368)" fill="none" stroke="#535776" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
                                    </g>
                                </svg>
                            </div>
                            <div class="common_heading">
                                <h1 class="header_title">Account Activities</h1>
                                <p class="header_content">View all activity with your account, purchases, and sales</p>
                            </div>
                        </div>
                    </div>

                    <div class="inner_content_wrapper dashboard_one_content">
                        <div class="cm_form_wrap">
                            <div class="cm_date_box cm_width">
                                <label class="input_wrap">
                                    <input type="text" placeholder="Search"/>
                                    <span class="calendar_icon">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.58317 17.5001C13.9554 17.5001 17.4998 13.9557 17.4998 9.58341C17.4998 5.21116 13.9554 1.66675 9.58317 1.66675C5.21092 1.66675 1.6665 5.21116 1.6665 9.58341C1.6665 13.9557 5.21092 17.5001 9.58317 17.5001Z" stroke="#6C6C6C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M18.3332 18.3334L16.6665 16.6667" stroke="#6C6C6C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>                                            
                                    </span>
                                </label>
                            </div>
                            <div class="cm_date_box cm_width">
                                <label class="input_wrap">
                                    <input type="number" placeholder="Date Range"/>
                                    <span class="calendar_icon">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 2V5" stroke="#6C6C6C" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M16 2V5" stroke="#6C6C6C" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M3.5 9.09009H20.5" stroke="#6C6C6C" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z" stroke="#6C6C6C" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M15.6947 13.7H15.7037" stroke="#6C6C6C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M15.6947 16.7H15.7037" stroke="#6C6C6C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M11.9955 13.7H12.0045" stroke="#6C6C6C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M11.9955 16.7H12.0045" stroke="#6C6C6C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M8.29431 13.7H8.30329" stroke="#6C6C6C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M8.29431 16.7H8.30329" stroke="#6C6C6C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </span>
                                </label>
                            </div>
                            <div class="select_dropdown cm_width">
                                <select class="wide" style={{display:"none"}}>
                                    <option data-display="Category">Category</option>
                                    <option value="1">Category Item 1</option>
                                    <option value="2">Category Item 2</option>
                                    <option value="3">Category Item 3</option>
                                </select>
                            </div>
                            <div class="button_wraper"> <button class="cm_btn web_btn">Clear</button></div>
                        </div>
                        
                        <div class="inventory_table_wrap">
                            <div class="table-responsive cm_table_responsive ">
                                <div class="tbl-header"></div>
                                    <table class="cm_table fold-table">
                                        <thead class="cm_thead">
                                            <tr class="cm_thead_list">
                                                <th class="th_list cm_name"><div class="th_item_wrap"><h6>Event</h6></div></th>
                                                <th class="th_list cm_date"><div class="th_item_wrap"><h6>Category</h6></div></th>
                                                <th class="th_list cm_tickets"><div class="th_item_wrap"><h6>Date</h6></div></th>                                                
                                            </tr>
                                        </thead>
                                    {/* </table>
                                </div> 
                                <div class="tbl-content">
                                    <table class="cm_table">  */}
                                        <tbody class="cm_tbody">
                                            <tr class="cm_tr">
                                                <td class="cm_td"><h4>2x Peter Kay - 2nd December 2022</h4></td>
                                                <td class="cm_td"><h5 class="blue_text"><span></span>Listed</h5></td>
                                                <td class="cm_td"><h4>24 May, 2020</h4></td>
                                            </tr>
                                            <tr class="cm_hi"></tr>
                                            <tr class="cm_tr">
                                                <td class="cm_td"><h4>2x Peter Kay - 2nd December 2022</h4></td>
                                                <td class="cm_td"><h5 class="blue_text"><span></span>Listed</h5></td>
                                                <td class="cm_td"><h4>22 Oct, 2020</h4></td>
                                            </tr>
                                            <tr class="cm_hi"></tr>
                                            <tr class="cm_tr">
                                                <td class="cm_td"><h4>3x Peter Kay - 2nd December 2022</h4></td>
                                                <td class="cm_td"><h5 class="blue_text"><span></span>Listed</h5></td>
                                                <td class="cm_td"><h4>24 May, 2020</h4></td>
                                            </tr>
                                            <tr class="cm_hi"></tr>
                                            <tr class="cm_tr">
                                                <td class="cm_td"><h4>3x Peter Kay - 2nd December 2022</h4></td>
                                                <td class="cm_td"><h5 class="blue_text"><span></span>Listed</h5></td>
                                                <td class="cm_td"><h4>17 Oct, 2020</h4></td>
                                            </tr>
                                            <tr class="cm_hi"></tr>
                                            <tr class="cm_tr">
                                                <td class="cm_td"><h4>1x Peter Kay - 2nd December 2022</h4></td>
                                                <td class="cm_td"><h5 class="blue_text"><span></span>Listed</h5></td>
                                                <td class="cm_td"><h4>8 Sep, 2020</h4></td>
                                            </tr>
                                            <tr class="cm_hi"></tr>
                                            <tr class="cm_tr">
                                                <td class="cm_td"><h4>1x Peter Kay - 2nd December 2022</h4></td>
                                                <td class="cm_td"><h5 class="blue_text"><span></span>Listed</h5></td>
                                                <td class="cm_td"><h4>21 Sep, 2020</h4></td>
                                            </tr>
                                            <tr class="cm_hi"></tr>
                                            <tr class="cm_tr">
                                                <td class="cm_td"><h4>4x Peter Kay - 2nd December 2022</h4></td>
                                                <td class="cm_td"><h5 class="blue_text"><span></span>Listed</h5></td>
                                                <td class="cm_td"><h4>1 Feb, 2020</h4></td>
                                            </tr>
                                            <tr class="cm_hi"></tr>
                                            <tr class="cm_tr">
                                                <td class="cm_td"><h4>4x Peter Kay - 2nd December 2022</h4></td>
                                                <td class="cm_td"><h5 class="blue_text"><span></span>Listed</h5></td>
                                                <td class="cm_td"><h4>1 Feb, 2020</h4></td>
                                            </tr>
                                            <tr class="cm_hi"></tr>
                                            <tr class="cm_tr">
                                                <td class="cm_td"><h4>5x Peter Kay - 2nd December 2022</h4></td>
                                                <td class="cm_td"><h5 class="blue_text"><span></span>Listed</h5></td>
                                                <td class="cm_td"><h4>1 Feb, 2020</h4></td>
                                            </tr>
                                            <tr class="cm_hi"></tr>
                                            <tr class="cm_tr">
                                                <td class="cm_td"><h4>5x Peter Kay - 2nd December 2022</h4></td>
                                                <td class="cm_td"><h5 class="blue_text"><span></span>Listed</h5></td>
                                                <td class="cm_td"><h4>1 Feb, 2020</h4></td>
                                            </tr>
                                            <tr class="cm_hi"></tr>
                                            <tr class="cm_tr">
                                                <td class="cm_td"><h4>7x Peter Kay - 2nd December 2022</h4></td>
                                                <td class="cm_td"><h5 class="blue_text"><span></span>Listed</h5></td>
                                                <td class="cm_td"><h4>1 Feb, 2020</h4></td>
                                            </tr>
                                            <tr class="cm_hi"></tr>
                                            <tr class="cm_tr">
                                                <td class="cm_td"><h4>2x Peter Kay - 2nd December 2022</h4></td>
                                                <td class="cm_td"><h5 class="blue_text"><span></span>Listed</h5></td>
                                                <td class="cm_td"><h4>24 May, 2020</h4></td>
                                            </tr>
                                            <tr class="cm_hi"></tr>
                                            <tr class="cm_tr">
                                                <td class="cm_td"><h4>2x Peter Kay - 2nd December 2022</h4></td>
                                                <td class="cm_td"><h5 class="blue_text"><span></span>Listed</h5></td>
                                                <td class="cm_td"><h4>22 Oct, 2020</h4></td>
                                            </tr>
                                            <tr class="cm_hi"></tr>
                                            <tr class="cm_tr">
                                                <td class="cm_td"><h4>3x Peter Kay - 2nd December 2022</h4></td>
                                                <td class="cm_td"><h5 class="blue_text"><span></span>Listed</h5></td>
                                                <td class="cm_td"><h4>24 May, 2020</h4></td>
                                            </tr>
                                            <tr class="cm_hi"></tr>
                                            <tr class="cm_tr">
                                                <td class="cm_td"><h4>3x Peter Kay - 2nd December 2022</h4></td>
                                                <td class="cm_td"><h5 class="blue_text"><span></span>Listed</h5></td>
                                                <td class="cm_td"><h4>17 Oct, 2020</h4></td>
                                            </tr>
                                            <tr class="cm_hi"></tr>
                                            <tr class="cm_tr">
                                                <td class="cm_td"><h4>1x Peter Kay - 2nd December 2022</h4></td>
                                                <td class="cm_td"><h5 class="blue_text"><span></span>Listed</h5></td>
                                                <td class="cm_td"><h4>8 Sep, 2020</h4></td>
                                            </tr>
                                            <tr class="cm_hi"></tr>
                                            <tr class="cm_tr">
                                                <td class="cm_td"><h4>1x Peter Kay - 2nd December 2022</h4></td>
                                                <td class="cm_td"><h5 class="blue_text"><span></span>Listed</h5></td>
                                                <td class="cm_td"><h4>21 Sep, 2020</h4></td>
                                            </tr>
                                            <tr class="cm_hi"></tr>
                                            <tr class="cm_tr">
                                                <td class="cm_td"><h4>4x Peter Kay - 2nd December 2022</h4></td>
                                                <td class="cm_td"><h5 class="blue_text"><span></span>Listed</h5></td>
                                                <td class="cm_td"><h4>1 Feb, 2020</h4></td>
                                            </tr>
                                            <tr class="cm_hi"></tr>
                                            <tr class="cm_tr">
                                                <td class="cm_td"><h4>4x Peter Kay - 2nd December 2022</h4></td>
                                                <td class="cm_td"><h5 class="blue_text"><span></span>Listed</h5></td>
                                                <td class="cm_td"><h4>1 Feb, 2020</h4></td>
                                            </tr>
                                            <tr class="cm_hi"></tr>
                                            <tr class="cm_tr">
                                                <td class="cm_td"><h4>5x Peter Kay - 2nd December 2022</h4></td>
                                                <td class="cm_td"><h5 class="blue_text"><span></span>Listed</h5></td>
                                                <td class="cm_td"><h4>1 Feb, 2020</h4></td>
                                            </tr>
                                            <tr class="cm_hi"></tr>
                                            <tr class="cm_tr">
                                                <td class="cm_td"><h4>5x Peter Kay - 2nd December 2022</h4></td>
                                                <td class="cm_td"><h5 class="blue_text"><span></span>Listed</h5></td>
                                                <td class="cm_td"><h4>1 Feb, 2020</h4></td>
                                            </tr>
                                            <tr class="cm_hi"></tr>
                                            <tr class="cm_tr">
                                                <td class="cm_td"><h4>7x Peter Kay - 2nd December 2022</h4></td>
                                                <td class="cm_td"><h5 class="blue_text"><span></span>Listed</h5></td>
                                                <td class="cm_td"><h4>1 Feb, 2020</h4></td>
                                            </tr>
                                            <tr class="cm_hi"></tr>
                                        </tbody>
                                    </table>
                                </div>
                                
                            </div>
                        </div>

                    </div>
              </div>
          </div>
          </body>

    )
}