import React from 'react'

import { ToastContainer, toast } from 'react-toastify';

import { useLocalStorage } from "usehooks-ts"

import 'react-toastify/dist/ReactToastify.css';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { convertEuropeanNumber } from '../Util/EUnumberFormatter';

const useStyles = makeStyles({
    
    input: {
        "&.Mui-focused": {
          border: "none",
          
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
            borderRadius: 'none'
          },
          width: '470px',
          right: '20px'
      }
});
export const AddSale = ({id,callback}) => {

    // const [listed, setListed] = React.useState("");
    // const [price, setProceedsPerTicket] = React.useState("");
    // const [platformListed, setSiteListedOn] = React.useState("");
    const [quantitySold, setTicketsSold] = React.useState("");
    const [price, setPrice] = React.useState(0);
    // const [status, setStatus] = React.useState("");
    const classes = useStyles();

    const handleEdit = () => {
        const purchase = { id,price, quantitySold};


        let wrongFields = []
        //validate every field
        for (const i in purchase) {
            if (purchase[i] === '' && i === "listed") {
                wrongFields.push(i)

                continue
            }
        }

        purchase.price = convertEuropeanNumber(purchase.price)

        

        if (wrongFields.length > 0) {
            toast("Please fill in all fields")
            return
        }

        fetch(`${process.env.REACT_APP_BASE_URL}addSale`, {
            credentials: 'include',
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(purchase)
        }).then(() => {
            toast.success("Sale added successfully")
            callback(id, quantitySold,purchase.price)

        })
    }

    return (

        <div class="modal fade" id="exampleModal_6" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="modal_header_content">
                            <h5 class="modal-title" id="exampleModalLabel">Add Sale</h5>
                            <p>Add a sale here</p>
                        </div>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.00098 5L19.0005 18.9996" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M4.99947 18.9996L18.999 5" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="modal_body_details">
                            <form class="modal_form">
                                <div class="cm_form_wrap">
                                <div class="cm_col_6">
                                        

                                        <div class="cm_date_box modal_cm_width">
                                            <label>Total Proceeds</label>
                                            <div class="input_wrap">
                                                <input type="number" value={price} onChange={e => setPrice(e.target.value)} />
                                            </div>
                                        </div>

                                        




                                    </div>

                                    <div class="cm_col_6">
                                        

                                      

                                        <div class="cm_date_box modal_cm_width">
                                            <label>Amount Sold</label>
                                            <div class="input_wrap">
                                                <input type="number" value={quantitySold} onChange={e => setTicketsSold(e.target.value)} />
                                            </div>
                                        </div>




                                    </div>

                                    
                                </div>
                            </form>
                        </div>
                        <div class="button_wraper text-center">
                            <button class="cm_btn web_btn" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleEdit()}>Add</button>
                            <button class="cm_btn web_btn grey_color" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}


export const RemoveSale = ({id, callback}) => {

    // const [listed, setListed] = React.useState("");
    // const [price, setProceedsPerTicket] = React.useState("");
    // const [platformListed, setSiteListedOn] = React.useState("");
    const [quantitySold, setTicketsSold] = React.useState("");
    const [price, setPrice] = React.useState(0);
    // const [status, setStatus] = React.useState("");
    const classes = useStyles();

    const handleEdit = () => {
        const purchase = { id,price, quantitySold};


        let wrongFields = []
        //validate every field
        for (const i in purchase) {
            if (purchase[i] === '' && i === "listed") {
                wrongFields.push(i)

                continue
            }
        }

        purchase.price = convertEuropeanNumber(purchase.price)

        

        if (wrongFields.length > 0) {
            toast("Please fill in all fields")
            return
        }

        fetch(`${process.env.REACT_APP_BASE_URL}removeSale`, {
            credentials: 'include',
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(purchase)
        }).then(() => {
            toast.success("Sale removed successfully")
            callback(id, quantitySold,purchase.price)

        })
    }

    return (

        <div class="modal fade" id="exampleModal_7" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="modal_header_content">
                            <h5 class="modal-title" id="exampleModalLabel">Remove Sale</h5>
                            <p>Remove a sale here</p>
                        </div>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.00098 5L19.0005 18.9996" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M4.99947 18.9996L18.999 5" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="modal_body_details">
                            <form class="modal_form">
                                <div class="cm_form_wrap">
                                    <div class="cm_col_6">
                                        

                                        <div class="cm_date_box modal_cm_width">
                                            <label>Sale Price</label>
                                            <div class="input_wrap">
                                                <input type="number" value={price} onChange={e => setPrice(e.target.value)} />
                                            </div>
                                        </div>

                                        




                                    </div>

                                    <div class="cm_col_6">
                                        

                                      

                                        <div class="cm_date_box modal_cm_width">
                                            <label>Amount Unsold</label>
                                            <div class="input_wrap">
                                                <input type="number" value={quantitySold} onChange={e => setTicketsSold(e.target.value)} />
                                            </div>
                                        </div>




                                    </div>

                                    
                                </div>
                            </form>
                        </div>
                        <div class="button_wraper text-center">
                            <button class="cm_btn web_btn" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleEdit()}>Add</button>
                            <button class="cm_btn web_btn grey_color" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}