import React from 'react'

import { ToastContainer, toast } from 'react-toastify';

import { useLocalStorage } from "usehooks-ts"

import 'react-toastify/dist/ReactToastify.css';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { convertEuropeanNumber } from '../Util/EUnumberFormatter';
const useStyles = makeStyles({
    
    input: {
        "&.Mui-focused": {
          border: "none",
          
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
            borderRadius: 'none'
          },
          width: '470px',
          right: '20px'
      }
});



export const OverridePurchase = ({ id, status, platformListed, price, orderNumber, setProceedsPerTicket, setSiteListedOn, setStatus, setOrderNumber, callback, sales }) => {

    // const [listed, setListed] = React.useState("");
    // const [price, setProceedsPerTicket] = React.useState("");
    // const [platformListed, setSiteListedOn] = React.useState("");
    const [quantitySold, setTicketsSold] = React.useState("");
    // const [status, setStatus] = React.useState("");
    const classes = useStyles();

let statuss = []
    if(sales >0){
        statuss = ["Sold - Action Required", "Sold - Complete", "Payment Received"]
    }else {
        statuss = ["Not Listed", "Listed", "Unsold"]
    }

    const handleEdit = () => {
        const purchase = { id, status, price, platformListed, orderNumber };


        let wrongFields = []
        //validate every field
        for (const i in purchase) {
            if (purchase[i] === '' && i === "listed") {
                wrongFields.push(i)

                continue
            }
        }

        purchase.price = convertEuropeanNumber(purchase.price)

        

        if (wrongFields.length > 0) {
            toast("Please fill in all fields")
            return
        }

        fetch(`${process.env.REACT_APP_BASE_URL}overrideManualPurchase`, {
            credentials: 'include',
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(purchase)
        }).then(() => {
            toast.success("Purchase overrriden successfully")
            callback(id, status, purchase.price, platformListed, quantitySold)

        })
    }

    return (

        <div class="modal fade" id="exampleModal_2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="modal_header_content">
                            <h5 class="modal-title" id="exampleModalLabel">Override listing / sale details</h5>
                            <p>Contact us if you are experiencing issues with information coming from viagogo/stubhub</p>
                        </div>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.00098 5L19.0005 18.9996" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M4.99947 18.9996L18.999 5" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="modal_body_details">
                            <form class="modal_form">
                                <div class="cm_form_wrap">
                                    <div class="cm_col_6">
                                        <div class="cm_date_box modal_cm_width">
                                            <label>Status</label>
                                            <div class="input_wrap">
                                                <select class="nice-select wide" style={{
                                                    paddingLeft: "0px",
                                                    width: "106%",
                                                    marginLeft: "-24px"
                                                }} value={status} onChange={e => setStatus(e.target.value)}>

                                                    {
                                                        statuss.map((item) => {
                                                            return (
                                                                <option value={item}>{item}</option>
                                                            )
                                                        })
                                                    }
                                                    {/* <option data-display="Not Listed">Not Listed</option>
                                                    <option value="Listed">Listed</option>
                                                    <option value="Sold - Action Required">Sold - Action Required</option>
                                                    <option value="Sold - Complete">Sold - Complete</option>
                                                    <option value="Payment Received">Payment Received</option> */}
                                                </select>
                                            </div>
                                        </div>

                                        <div class="cm_date_box modal_cm_width">
                                            <label>Total Proceeds</label>
                                            <div class="input_wrap">
                                                <input type="number" value={price} onChange={e => setProceedsPerTicket(e.target.value)} />
                                            </div>
                                        </div>


                                    </div>

                                    <div class="cm_col_6">
                                        <div class="cm_date_box modal_cm_width">
                                            <label>Site Listed On</label>
                                            <div class="input_wrap">
                                                <Autocomplete
                                                    disableClearable
                                                        freeSolo
                                                        className={classes.input}
                                                        value={platformListed}
                                                    options={["AXS", "StubHub", "Ticketmaster", "Twickets", "Viagogo"]}
                                                    onChange={(event, value) => setSiteListedOn(value)}
                                                    renderInput={(params) => <TextField {...params} placeholder={platformListed} className={classes.input} onChange={e=> setSiteListedOn(e.target.value)}/>}
                                                />
                                            </div>
                                        </div>

                                        <div class="cm_date_box modal_cm_width">
                                            <label>Order Number</label>
                                            <div class="input_wrap">
                                                <input type="text" value={orderNumber} onChange={e => setOrderNumber(e.target.value)} />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="button_wraper text-center">
                            <button class="cm_btn web_btn" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleEdit()}>Save</button>
                            <button class="cm_btn web_btn grey_color" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}