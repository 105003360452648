import { Navigate, Outlet, redirect } from 'react-router-dom';
import { Sidebar } from './sidebar';
import { useState } from 'react';

export const SidebarLayout = () => {

  function checkIsAuthed() { 
    //Uncomment this to make it active
    return document.cookie.match(new RegExp('(^| )' + "token" + '=([^;]+)'))
  }
  return checkIsAuthed()?(

   
  <>
    <Sidebar/>
    <Outlet />
  </>
  ):(
    <Navigate to="/login" />
  )
  }